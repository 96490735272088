.forgotten-password{
	.form-fields{
		.row{
			align-items: center;

			label{
				padding-top: 0;
			}
	
			button{
				height: unset;
				background: var(--ternary);
				color: var(--font_secondary);
				border-radius: 50px;
				border: 0;
				font-size: 1.0625rem;
				padding: 10px 20px;
				text-decoration: none;
				font-family: $font_inter;
				font-weight: 600;
				transition: all .3s ease;
	
				&:hover{
					background: var(--secondary);
				}
			}
		}
		
	}
}