#coordonnees{
	#background{
		height: 800px;
		z-index: 0;

		@media (max-width: 991px) {
			display: none;
		}

		.bulle-nom{
			color: var(--font);
			font-size: .9rem;
			font-family: $font_ibm;
			font-weight: 400;
		}
	}

	#all_coordonnees{
		background: var(--primary);
		color: var(--font);
		padding: 4.375rem 5rem 5rem;
		max-width: 35rem;
		font-family: $font_ibm;
		font-weight: 400;
		z-index: 1;

		&.absolute{
			top: 90px;
			right: 200px;
			position: absolute;
			z-index: 1;

			@media (max-width: 1400px) {
				right: 100px;
			}
			
			@media (max-width: 1180px) {
				top: 50px;
			}
			
			@media (max-width: 1080px) {
				position: relative;
				top: 0;
				right: 0;
				margin: 0;
				max-width: inherit;
			}
		}

		&.relative{
			margin: auto;
			position: relative;
			top: 50px;

			@media (max-width: 1080px) {
				top: 0;
			}
		}
		
		@media (max-width: 650px) {
			padding: 1.375rem 1.25rem 3rem;
			max-width: 26rem;
			margin-bottom: 1rem;
		}

		#title_coordonnees{
			color: var(--font);
			font-size: 2.063rem;
			font-family: $font_marcellus;
			font-weight: 400;
		}

		#info_coordonnees{
			padding-bottom: 3.125rem;

			.tel{
				letter-spacing: -0.88px;
				font-size: 1.375rem;
				font-family: $font_inter;
				font-weight: 600;
			}

			.info_tel,
			.hours,
			.contact_form{
				letter-spacing: -0.32px;

				a{
					color: var(--font);
				}
			}

			.svg{
				width: 35px;
				text-align: center;

				svg{
					fill: var(--font_secondary);
					height: 1.25rem;
				}
			}
			
		}

		.slick-track {
			display: flex !important;
		}
	
		.slick-slide{
			height: inherit !important;
		}
	
		.slick-dots {
			bottom: 0;

			@media (max-width: 650px) {
				width: 95%;
			}
	
			li {
				height: 10px;
				width: 10px;
	
				button {
					border: 2px solid var(--secondary);
					border-radius: 50%;
					height: 10px;
					width: 10px;
	
					&:focus {
						background: var(--background);
					}
	
					&:before {
						color: transparent;
					}
	
					&[aria-selected]{
						border: 2px solid var(--background);
						background: var(--background);
					}
				}
			}
		}

		hr{
			background-color: var(--font_secondary);
			opacity: .35;
		}

		#info_rencontre{
			padding-top: 3.125rem;

			#title_rencontre{
				letter-spacing: -2px;
				font-size: 3.125rem;
				font-family: $font_inter;
				font-weight: 300;

				@media (max-width: 450px) {
					font-size: 2rem;
				}
			}

			#description_rencontre{
				letter-spacing: -0.32px;
			}

			#btn_rencontre{
				a{
					background: var(--secondary);
					color: var(--font_secondary);
					text-decoration: none;
					border-radius: 50px;
					border: 0;
					font-weight: 600;
					font-size: 1.0625rem;
					padding: 25px 45px;
					font-family: $font_inter;
					font-weight: 600;

					@media (max-width: 450px) {
						padding: 20px;
					}

					svg{
						height: 20px;
						fill: var(--primary);
					}

					&:hover{
						text-decoration: underline;
					}
				}
				
			}
		}
	}
}