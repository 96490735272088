#left-column{
	background: var(--background);
	padding-left: 2rem;
	padding-right: 5rem;

	@media (max-width: 1600px) {
		padding-right: 2rem;
	}

	@media (max-width: 1200px) {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	@media (max-width: 968px) {
		padding-left: 0;
		padding-right: 0;
	}

	.square{
		background: var(--primary);
		width: 75px;
		height: 160px;
		top: 25px;
		left: 0;

		@media (max-width: 1200px) {
			display: none;
		}
	}

	.block-categories{
		display: none;
	}
}

// Cas spécifique de la page category
#category{
	#content-wrapper{
		@media (max-width: 968px) {
			padding-left: 0;
			padding-right: 0;
		}
	}
}


#content-wrapper{
	color: var(--font);
	background: var(--background);

	.chapeau{
		background: var(--hover);
		padding: 5.5rem;
		margin: 0 0 56px;
		border: 0;

		@media (max-width: 450px) {
			padding-left: 3rem;
			padding-right: 3rem;
		}
		
		h1{
			font-size: 3.438rem;
			letter-spacing: -1.1px;
			font-family: $font_marcellus;
			font-weight: 400;

			@media (max-width: 450px) {
				font-size: 2.438rem;
			}
		}
	}

	.page-content{
		font-family: $font_ibm;
		font-weight: 400;
		margin-bottom: 3rem;

		&.card{
			box-shadow: none;
			border: none;
		}
		
		img{
			height: 100%;
			object-fit: cover;
		}
		
		iframe{
			width: 100%;
			height: 100%;
			min-height: 750px;
		}
	}
}