#single_product{
	background: var(--background);
	color: var(--font);
	padding-top: 80px;
	padding-bottom: 80px;

	.product-container{
		#content{
			.product-flags{
				.discount{
					background: var(--primary);
					color: var(--font);
				}

				.online-only{
					background: var(--secondary);
					color: var(--font_secondary);
				}
			}

			.images-container{
				.js-qv-mask{
					.product-images{
						li{
							img{
								&.selected{
									border: 2px solid var(--ternary);
								}

								&:not([class*="selected"]){
									&:hover{
										border: 2px solid var(--primary);
									}
								}
							}
						}
					}
				}
			}

			.scroll-box-arrows{
				i{
					bottom: 0;
					color: var(--font);
				}
			}
		}

		#part_right{
			h1{
				font-size: 3.438rem;
				letter-spacing: -1.1px;
				font-family: $font_marcellus;
				font-weight: 400;
				border-bottom: 1px solid rgba($black, .125);
				padding-bottom: 13px;
				margin-bottom: 40px;
			}

			.product-prices{
				.product-discount{
					.regular-price{
						color: var(--information);
					}
				}

				.current-price{
					span{
						&[itemprop="price"]{
							color: var(--ternary);
						}
					}
				}
				
				.discount{
					background: var(--primary);
					color: var(--font);
				}

				.tax-shipping-delivery-label{
					display: none;
				}
			}

			.product-information{
				color: var(--font);

				.product-description{
					p{
						span{
							font-size: 1rem !important;
							font-family: $font_inter;
							font-weight: 500;
						}
					}
				}

				.product-actions{
					form{
						font-size: 1rem;

						.product-add-to-cart{
							.product-quantity{
								flex-wrap: wrap;
								
								.qty{
									margin-right: 2rem;

									.bootstrap-touchspin{
										flex-wrap: unset;
									}
								}

								.add{
									button{
										background: var(--ternary);
										color: var(--font_secondary);
										display: flex;
										align-items: center;
										border-radius: 50px;
										border: 0;
										font-size: 1.0625rem;
										padding: 20px 30px;
										text-decoration: none;
										font-family: $font_inter;
										font-weight: 600;
										transition: all .3s ease;
	
										&:hover{
											background: var(--secondary);
										}
	
										.material-icons{
											line-height: normal;
										}
									}
								}
							}
						}
					}
				}

				.blockreassurance_product{
					margin-top: 25px;
					border-top: 1px solid rgba($black, .125);
					padding-top: 40px;
					border-bottom: 1px solid rgba($black, .125);

					div:not([class="block-title"]){
						display: flex;
						align-items: center;
						margin-bottom: 1rem;
	
						svg{
							height: 65px;
							width: 65px;
							fill: var(--ternary);
						}
	
						.block-title{
							font-family: $font_inter;
							font-weight: 600;
							font-size: 1rem;
						}
					}
				}

				.tabs{
					box-shadow: none;
					font-family: $font_inter;
					padding: 0;

					.nav-tabs{
						.nav-item{
							text-transform: uppercase;
						}
						
						li{
							a{
								color: var(--font);

								&.active{
									color: var(--ternary);
									border-bottom: 2px solid var(--ternary);
								}

								&:hover{
									border-bottom: 2px solid var(--ternary);
								}
							}
						}
					}

					#tab-content{
						margin-top: 1.5rem;

						.tab-pane{
							label, span, p, dl{
								color: var(--font);
							}

							&.fade.in{
								opacity: 1;
								padding: 0 1em;
							}

							dt, dd{
								&:nth-of-type(even){
									background: var(--primary);
								}
								
								&:nth-of-type(odd){
									background: var(--hover);
								}
							}
						}
					}
				}
			}
		}
	}

	.modal{
		&.fade{
			&.in{
				opacity: 1;
				background: rgba(0, 0, 0, .7);

				.modal-content{
					.modal-body{
						.image-caption{
							border-top: none;

							span{
								color: var(--font);
								font-size: 1rem !important;
								font-family: $font_inter;
								font-weight: 500;
							}
						}

						aside{
							.product-images{
								li{
									img{
										&.selected,
										&:hover{
											border: 3px solid var(--ternary);
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

#blockcart-modal{
	&.show{
		opacity: 1;

		.modal-content{
			.modal-header{
				@media (max-width: 450px) {
					flex-direction: column;
					align-items: flex-end;
				}


				button,
				.modal-title{
					color: var(--font);
				}
			}

			.modal-body{
				.divide-right{
					h6{
						color: var(--ternary);
						font-family: $font_marcellus;
						font-weight: 400;
					}

					span{
						color: var(--font);
					}
				}

				.cart-content{
					@media (max-width: 500px) {
						text-align: center;
					}

					.cart-products-count{
						color: var(--font);
						font-family: $font_inter;
						font-weight: 600;
					}

					.product-total{
						background: var(--primary);
					}

					.cart-content-btn{
						@media (max-width: 500px) {
							flex-direction: column;	
						}

						button{
							background: none;
							border: none;
							color: var(--font);
							box-shadow: none;

							&:hover
							&.focus{
								background: none;
								border: none;
								color: var(--ternary);
								box-shadow: none;
							}
						}

						a{
							background: var(--ternary);
							color: var(--font_secondary);
							border-radius: 50px;
							border: 0;
							font-size: 1.0625rem;
							padding: 20px 30px;
							text-decoration: none;
							font-family: $font_inter;
							font-weight: 600;
							transition: all .3s ease;

							&:hover{
								background: var(--secondary);
							}
						}
					}
				}
			}
		}
	}
}