body#checkout{
	#header{
		#_desktop_cart{
			.blockcart{
				&.active{
					background: var(--ternary);
				}

				// a{
				// 	text-decoration: none;
				// 	color: var(--font);
				// 	font-size: 1rem;
				// 	letter-spacing: .0025rem;
				// 	font-family: $font_inter;
				// 	font-weight: 600;

				// 	svg{
				// 		height: 27px;
				// 		width: 27px;
				// 		fill: var(--font);
				// 	}
				// }
			}
		}
	}

	#checkout_info{
		color: var(--font);
		background: var(--background);
		margin-bottom: 0!important;
		padding: 0;
		box-shadow: unset;
		border: none;
		min-height: calc(100vh - 100px - 72px);

		.cart-grid-body{
			.checkout-step{
				box-shadow: none;
				border-color: var(--information);
	
				h1{
					font-size: 3.438rem;
					letter-spacing: -1.1px;
					font-family: $font_marcellus;
					font-weight: 400;
					color: var(--ternary);

					@media (max-width: 400px) {
						font-size: 3rem;
					}
				}
	
				.content{
					p{
						& {
							> a{
								text-decoration: none;
								color: var(--font);
								font-family: $font_inter;
								font-weight: 600;
	
								&:hover{
									text-decoration: underline;
								}
							}

							> small{
								color: var(--information);
							}
						}
					}

					.nav{
						align-items: center;

						@media (max-width: 600px) {
							flex-direction: column;
						}
	
						.nav-item{
							.nav-link{
								color: var(--font);
		
								&.active{
									color: var(--ternary)
								}
							}
	
							&:nth-child(2){
								font-size: 2rem;

								@media (max-width: 600px) {
									display: none;
								}
							}
						}
					}
	
					form{
						h2{
							color: var(--font);
						}

						#delivery-addresses{
							.address-item{
								background: var(--background);
								border: none;

								&.selected{
									border: solid 2px var(--primary);
								}
							}

							hr{
								display: none;
							}
						}

						.custom-radio{
							input[type=radio]:checked+span{
								background-color: var(--primary);
							}
						}

						.form-group{
							label{
								color: var(--font);
							}

							.custom-checkbox{
								span{
									margin-top: 0;
								}
							}

							.form-control,
							.input-group{
								&.focus{
									outline: none;
								}

								&:focus{
									box-shadow: none;
									border-color: var(--primary);
								}
							}

							.input-group-btn{
								@media (max-width: 600px) {
									display: none;
								}

								button{
									background: var(--ternary);
									color: var(--font_secondary);

									&:hover{
										background: var(--secondary);
									}
								}
							}

							.form-control-comment{
								color: var(--information);
							}
						}

						.forgot-password{
							@media (max-width: 768px) {
								padding-left: 0;
							}

							a{
								text-decoration: none;
								color: var(--font);
								font-family: $font_inter;
								font-weight: 600;

								&:hover{
									text-decoration: underline;
									color: var(--font);
								}
							}
						}

						.delivery-options{
							.delivery-option{
								background: var(--hover);
								color: var(--font);
								border-right: none;

								.custom-radio{
									input[type=radio]:checked+span{
										background-color: var(--font);
									}
								}
							}
						}

						.js-terms{
							a{
								font-family: $font_inter;
								font-weight: 600;
								color: var(--font);

								&:hover{
									text-decoration: underline;
									color: var(--ternary);
								}
							}
						}

						.continue{
							background: var(--ternary);
							color: var(--font_secondary);
							border-radius: 50px;
							border: 0;
							font-size: 1.0625rem;
							padding: 20px 30px;
							text-decoration: none;
							font-family: $font_inter;
							font-weight: 600;
							transition: all .3s ease;

							&:hover{
								background: var(--secondary);
							}
						}

						.cancel-address{
							color: var(--font);
							padding: 12px 10px;
						}

						&#conditions-to-approve{
							padding-bottom: 1rem;
							
							ul{
								padding-left: 0;
							}
						}
					}

					#payment-confirmation{
						button{
							background: var(--ternary);
							color: var(--font_secondary);
							border-radius: 50px;
							border: 0;
							font-size: 1.0625rem;
							padding: 20px 30px;
							text-decoration: none;
							font-family: $font_inter;
							font-weight: 600;
							transition: all .3s ease;

							&:hover{
								background: var(--secondary);
							}
						}
					}
				}

				.text-muted{
					color: var(--information) !important;
				}
			}
		}

		.cart-grid-right{
			.cart-summary-products{
				a{
					color: var(--font);

					&:hover{
						text-decoration: underline;
					}
				}

				.collapse.in{
					display: block;

					.product-price{
						color: var(--ternary);
					}

					.text-muted{
						color: var(--information);
					}
				}
			}

			.cart-summary-totals{
				.cart-summary-line{
					background: var(--primary);
				}
			}

			.blockreassurance_product{
				div:not([class="block-title"]){
					display: flex;
					align-items: center;
					margin-bottom: 1rem;

					svg{
						height: 65px;
						width: 65px;
						fill: var(--ternary);
					}

					.block-title{
						font-family: $font_inter;
						font-weight: 600;
						font-size: 1rem;
					}
				}
			}
		}
	}

	#footer{
		background: var(--secondary);
		color: var(--font_header);
		font-family: $font_ibm;
		font-weight: 400;
		font-size: 1rem;
		padding: 1.5rem 0;
	}
}