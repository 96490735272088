body#order-confirmation{
	#wrapper{
		#main{
			#content-hook_order_confirmation{
				color: var(--font);
				background: var(--background);
				border: none;
				box-shadow: none;

				.chapeau{
					background: var(--hover);
					padding: 5.5rem;
					margin: 0 0 56px;
					border: 0;

					@media (max-width: 768px) {
						padding: 4rem;
					}

					@media (max-width: 450px) {
						padding-left: 2rem;
						padding-right: 2rem;
					}
					
					h1{
						font-size: 3.438rem;
						letter-spacing: -1.1px;
						font-family: $font_marcellus;
						font-weight: 400;

						@media (max-width: 768px) {
							font-size: 3rem;
						}

						@media (max-width: 450px) {
							font-size: 2.438rem;
						}
					}

					p{
						a{
							color: var(--ternary);
							font-family: $font_inter;
							font-weight: 600;
						}
					}
				}
			}

			.page-order-confirmation{
				.card-block{
					#order-items{
						.order-confirmation-table{
							hr{
								color: var(--primary);
								border-top: none;
							}

							table{
								tbody{
									.total-value{
										background: var(--primary);
									}
								}
							}
						}
					}
				}
			}
		}
	}
}