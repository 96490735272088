#content{
	margin-bottom: 0 !important;
	padding: 0;
	box-shadow: unset;
	border: none;
	color: var(--font);
	background: var(--background);

	#content-wrapper{
		#single_actualite{
			.chapeau{
				background: var(--hover);
				padding: 5.5rem;
				margin: 0 0 56px;
				border: 0;

				@media (max-width: 450px) {
					padding-left: 3rem;
					padding-right: 3rem;
				}
				
				h1{
					font-size: 3.438rem;
					letter-spacing: -1.1px;
					font-family: $font_marcellus;
					font-weight: 400;

					@media (max-width: 450px) {
						font-size: 2.438rem;
					}
				}
			}
		}
	}
}

#listing-actu{
	color: var(--font);
	background: var(--background);

	.chapeau{
		background: var(--hover);
		padding: 3rem;
		margin: -3rem 0 56px;
		border: 0;
		
		h1{
			font-size: 3.438rem;
			letter-spacing: -1.1px;
			font-family: $font_marcellus;
			font-weight: 400;
		}
	}

	#all_listing_actu{
		padding: 0 8rem;
		margin-left: 0;

		@media (max-width: 991px) {
			padding: 0 2rem;
			margin: 0;
		}
	}

	.actualite{
		padding-left: 2.5rem;
		padding-right: 2.5rem;

		@media (max-width: 1400px) {
			padding-left: 2rem;
			padding-right: 2rem;
		}
		
		@media (max-width: 1275px) {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		@media (max-width: 991px) {
			padding-left: 0;
			padding-right: 0;
		}
		
		.card{
			border: none;
			
			.img-fluid img{
				width: 100%;
				height: 300px;
				object-fit: cover;
			}

			.card-body{
				padding: 2.8rem 2.8rem 3.75rem 2.8rem;
				background: var(--background);
				transition:ease all 300ms;

				@media (max-width: 991px) {
					padding: 1.8rem 1.5rem 2.75rem;
					min-height: 200px;
				}

				.card-title{
					font-size: .938rem;
					font-family: $font_inter;
					font-weight: 600;

					a{
						color: inherit;
						text-decoration: none;
						text-transform: uppercase;
						font-size: 1rem;
					}
				}

				.card-text{
					letter-spacing: -0.36px;
					font-size: 1.125rem;
					font-family: $font_inter;
					font-weight: 500;
					text-align: left;
					
					@media (max-width: 991px) {
						letter-spacing: inherit;
						font-family: $font_ibm;
						font-weight: 400;
						font-size: 1rem;
						margin-bottom: 2rem;
					}
				}

				.actu_more{
					bottom: 30px;
					right: 20px;

					a{
						border-radius: 100%;
						padding: 22px;
						font-family: $font_inter;
						font-weight: 600;

						svg{
							height: 1.25rem;
						}
					}
				}
			}

			&:hover{
				.card-body{
					background: var(--secondary);

					.card-title{	
						a{
							color: var(--primary);
						}
					}

					.card-text{
						color: var(--font_secondary);
					}

					.actu_more{
						a{
							border: 1px solid var(--hover); //.25
							
							svg{
								fill: var(--font_secondary);
							}
						}
					}
				}
			}
		}
	}
}