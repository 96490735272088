@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Inter:wght@300;500;600&family=Marcellus&display=swap");
/* Fix IE Only */
body {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
}

body #wrapper {
    background: var(--background);
    box-shadow: none;
    padding-top: 0;
}

body h1,
  body h2,
  body h3 {
    text-transform: unset !important;
}

body p {
    color: inherit;
}

body .label,
  body label {
    color: inherit;
    text-align: inherit;
    font-size: inherit;
    margin-bottom: 0;
}

body .container::after,
  body .row::after,
  body .card-block::after,
  body .modal-header::after {
    content: none;
}

body .collapse {
    display: inherit;
}

body .page-footer {
    display: none;
}

body .text-justify {
    text-align: justify;
}

body .grecaptcha-badge {
    display: none;
}

#header .header-nav {
    background: var(--ternary);
    color: var(--font_header);
    font-family: "IBM Plex Sans", sans-serif;
    border: none;
    max-height: unset;
    margin-bottom: 0;
}

#header .header-nav #info_header {
    padding: 0 8rem;
}

@media (max-width: 1520px) {
    #header .header-nav #info_header {
        padding: 0 4rem;
    }
}

@media (max-width: 1370px) {
    #header .header-nav #info_header {
        padding: 0 2rem;
    }
}

@media (max-width: 970px) {
    #header .header-nav #info_header {
        padding: 0 1rem;
    }
}

#header .header-nav #info_header .tel {
    font-size: 1.375rem;
    letter-spacing: -0.88px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

#header .header-nav #info_header .info_tel,
    #header .header-nav #info_header .hours,
    #header .header-nav #info_header #rs {
    letter-spacing: -0.32px;
}

@media (max-width: 1230px) {
    #header .header-nav #info_header #rs {
        display: none !important;
    }
}

@media (max-width: 860px) {
    #header .header-nav #info_header .info_tel {
        display: none;
    }
}

#header .header-nav #info_header svg {
    fill: var(--primary);
    height: 1.25rem;
}

#header .header-nav #info_header .reseaux_sociaux {
    padding: 0 .5rem 0 0;
}

#header .header-nav #info_header .reseaux_sociaux svg {
    fill: var(--font_header);
    height: 25px;
    width: 25px;
    transition: ease all 300ms;
}

#header .header-nav #info_header .reseaux_sociaux svg:hover {
    fill: var(--primary);
}

#header .header-top {
    padding-bottom: 0;
}

#header .header-top #site-navigation {
    color: var(--font);
    background: var(--background);
    font-family: "Inter", sans-serif;
    font-weight: 600 !important;
    margin: 0;
    padding-left: 0;
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.125);
}

#header .header-top #site-navigation #logo {
    max-width: 330px;
    min-width: 220px;
    max-height: 80px;
    margin-left: 10%;
    color: var(--logo);
}

#header .header-top #site-navigation .navbar-toggler {
    border: none;
    background-image: none;
}

#header .header-top #site-navigation .navbar-toggler:focus {
    box-shadow: none;
    outline: none;
}

#header .header-top #site-navigation .navbar-toggler .custom-navbar-toggler-icon svg {
    height: 25px;
    fill: var(--secondary);
}

#header .header-top #site-navigation hr {
    background-color: var(--primary);
    height: 3px;
    opacity: 1;
}

#header .header-top #site-navigation #main_menu {
    min-height: unset;
}

#header .header-top #site-navigation #main_menu .menu {
    padding-left: 0;
    margin-bottom: 0;
}

@media (max-width: 991px) {
    #header .header-top #site-navigation #main_menu .menu {
        width: 100%;
    }
}

#header .header-top #site-navigation #main_menu .menu #top-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: auto;
}

@media (max-width: 991px) {
    #header .header-top #site-navigation #main_menu .menu #top-menu {
        max-width: 75%;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;
    }
}

#header .header-top #site-navigation #main_menu .menu #top-menu li {
    display: flex;
    flex-direction: column;
    padding-right: 1.5rem;
    letter-spacing: -0.34px;
    list-style: none;
    position: relative;
}

@media (max-width: 1550px) {
    #header .header-top #site-navigation #main_menu .menu #top-menu li {
        padding-right: .75rem;
    }
}

@media (max-width: 1425px) {
    #header .header-top #site-navigation #main_menu .menu #top-menu li {
        padding-right: .5rem;
    }
}

@media (max-width: 1300px) {
    #header .header-top #site-navigation #main_menu .menu #top-menu li {
        padding-right: .69rem;
    }
}

@media (max-width: 991px) {
    #header .header-top #site-navigation #main_menu .menu #top-menu li {
        padding-right: 0;
        width: 100%;
    }
}

#header .header-top #site-navigation #main_menu .menu #top-menu li a {
    width: 100%;
    text-decoration: none;
    padding: .1875rem .625rem .375rem;
    text-transform: none;
}

@media (max-width: 1550px) {
    #header .header-top #site-navigation #main_menu .menu #top-menu li a {
        padding: .1875rem .5rem .375rem;
    }
}

@media (max-width: 1425px) {
    #header .header-top #site-navigation #main_menu .menu #top-menu li a {
        padding: .1875rem .25rem .375rem;
    }
}

@media (max-width: 1300px) {
    #header .header-top #site-navigation #main_menu .menu #top-menu li a {
        padding: .1875rem 0rem .375rem;
    }
}

@media (max-width: 991px) {
    #header .header-top #site-navigation #main_menu .menu #top-menu li a {
        transition: all .2s ease-in-out;
        padding: 10px 5%;
        border-bottom: 1px solid var(--information);
    }
}

#header .header-top #site-navigation #main_menu .menu #top-menu li.menu-item-has-children a {
    display: flex;
}

#header .header-top #site-navigation #main_menu .menu #top-menu li.menu-item-has-children a.active::after {
    transform: rotate(180deg);
    padding-bottom: 1.5rem;
}

#header .header-top #site-navigation #main_menu .menu #top-menu li.menu-item-has-children a::after {
    content: url(../img/down.svg);
    width: 13px;
    height: 13px;
    margin-top: -1px;
    margin-left: .3rem;
}

#header .header-top #site-navigation #main_menu .menu #top-menu li.menu-item-has-children a:hover {
    text-decoration: none;
}

@media (max-width: 991px) {
    #header .header-top #site-navigation #main_menu .menu #top-menu li.menu-item-has-children a {
        justify-content: space-between;
    }
}

#header .header-top #site-navigation #main_menu .menu #top-menu li.menu-item-has-children:hover ul {
    display: flex;
    transition: .4s;
}

@media (max-width: 991px) {
    #header .header-top #site-navigation #main_menu .menu #top-menu li.menu-item-has-children:hover ul {
        display: none;
        border-top: none;
    }
}

#header .header-top #site-navigation #main_menu .menu #top-menu li.menu-item-has-children ul {
    display: none;
    flex-direction: column;
    background: var(--background);
    border-top: 3px solid var(--primary);
    min-width: 250px;
    padding: 20px 0;
    float: left;
    position: absolute;
    top: 100%;
    left: auto;
    z-index: 99999;
    margin-left: 0;
    opacity: 1;
    visibility: visible;
}

@media (max-width: 991px) {
    #header .header-top #site-navigation #main_menu .menu #top-menu li.menu-item-has-children ul {
        position: relative;
        box-shadow: none;
        top: unset;
        left: unset;
        padding: 0 0 0 7%;
        width: 100%;
        border-top: none;
    }
}

#header .header-top #site-navigation #main_menu .menu #top-menu li.menu-item-has-children ul li {
    padding: 0 20px;
    margin: 0;
    letter-spacing: -0.34px;
    line-height: 2rem;
}

@media (max-width: 991px) {
    #header .header-top #site-navigation #main_menu .menu #top-menu li.menu-item-has-children ul li {
        padding: 0;
    }
}

@media (max-width: 335px) {
    #header .header-top #site-navigation #main_menu .menu #top-menu li.menu-item-has-children ul li {
        padding-right: 10px;
    }
}

#header .header-top #site-navigation #main_menu .menu #top-menu li.menu-item-has-children ul li:hover {
    background: var(--hover);
}

@media (max-width: 991px) {
    #header .header-top #site-navigation #main_menu .menu #top-menu li.menu-item-has-children ul li:hover {
        background: transparent;
    }
}

#header .header-top #site-navigation #main_menu .menu #top-menu li.menu-item-has-children ul li a {
    padding: 10px 5%;
}

#header .header-top #site-navigation #main_menu .menu #top-menu li.menu-item-has-children ul li a::after {
    content: unset !important;
}

#header .header-top #site-navigation #main_menu .menu #top-menu li button {
    border: none;
    background: transparent;
    padding: 0;
}

#header .header-top #site-navigation #main_menu .menu #top-menu li button svg {
    height: 27px;
    width: 27px;
    fill: var(--font);
}

@media (max-width: 991px) {
    #header .header-top #site-navigation #main_menu #_desktop_user_info {
        display: none;
    }
}

#header .header-top #site-navigation #btn_header {
    background: var(--primary);
    letter-spacing: -0.32px;
    text-align: center;
}

@media (max-width: 1550px) {
    #header .header-top #site-navigation #btn_header {
        padding-left: 1rem !important;
    }
}

#header .header-top #site-navigation #btn_header a {
    font-size: 1rem;
}

#header .header-top #site-navigation #btn_header a:hover {
    text-decoration: none;
}

#header .header-top #site-navigation #btn_header .height_button {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

@media (max-width: 1199px) {
    #header .header-top #site-navigation #btn_header .height_button {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
}

#header .header-top #site-navigation #btn_header .link_btn_header svg {
    height: 20px;
    fill: var(--font);
}

#header .header-top #site-navigation a {
    color: var(--font);
    letter-spacing: 0.0025rem;
    font-size: 1.0625rem;
}

#header .header-top #site-navigation a:hover {
    text-decoration: underline;
    text-decoration-thickness: .15rem;
}

#header .header-top #_desktop_user_info .user-info a {
    text-decoration: none;
    padding: 0;
}

#header .header-top #_desktop_user_info .user-info a svg {
    height: 17px;
    width: 17px;
    fill: var(--font);
}

@media (max-width: 1199px) {
    #header .header-top #_desktop_user_info .user-info a svg {
        height: 20px;
        width: 20px;
    }
}

#header .header-top #_desktop_user_info .user-info a:hover {
    text-decoration: underline;
}

#header .header-top #_desktop_user_info .user-info #user_login {
    display: none;
    flex-direction: column;
    background: var(--background);
    border-top: 3px solid var(--primary);
    min-width: 220px;
    padding: 20px 0;
    float: left;
    position: absolute;
    top: 100%;
    right: 0;
    left: auto;
    z-index: 99999;
    margin-left: 0;
    margin-bottom: 0;
    opacity: 1;
    visibility: visible;
    box-shadow: 2px 1px 11px 2px rgba(0, 0, 0, 0.1);
}

#header .header-top #_desktop_user_info .user-info #user_login li {
    padding: 0 20px;
    margin: 0;
    letter-spacing: -0.34px;
    line-height: 2rem;
}

#header .header-top #_desktop_user_info .user-info #user_login li:hover {
    background: var(--hover);
}

#header .header-top #_desktop_user_info .user-info #user_login li a {
    padding: 10px 5%;
    margin-right: 0 !important;
    display: flex;
    justify-content: flex-end;
}

#header .header-top #_desktop_user_info .user-info #user_login li a::after {
    content: unset !important;
}

#header .header-top #_desktop_user_info .user-info.is_login:hover #user_login {
    display: flex;
}

#header .header-top #_desktop_cart .blockcart {
    background: transparent;
}

#header .header-top #_desktop_cart .blockcart a {
    text-decoration: none;
    text-align: center;
    padding: 0;
}

#header .header-top #_desktop_cart .blockcart a .cart-products-count {
    font-size: 1rem;
    color: var(--font);
}

#header .header-top #_desktop_cart .blockcart svg {
    height: 22px;
    width: 22px;
    fill: var(--font);
}

@media (max-width: 1199px) {
    #header .header-top #_desktop_cart .blockcart svg {
        height: 20px;
        width: 20px;
    }
}

#colophon {
    background: var(--secondary);
    color: var(--font_secondary);
}

#colophon #pop-up {
    color: var(--font);
    opacity: 1;
}

#colophon #pop-up .modal-content {
    background: var(--hover);
    border: none;
}

#colophon #pop-up .modal-content .modal-header {
    border: none;
}

#colophon #pop-up .modal-content .modal-header .modal-title {
    font-family: "Marcellus", serif;
    font-weight: 400;
}

#colophon #pop-up .modal-content .modal-header .btn-close {
    margin: -.5rem -.5rem -.5rem 0.5rem;
}

#colophon #pop-up .modal-content .modal-body {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
}

#colophon #pop-up .modal-content .modal-body .img-fluid {
    max-width: 468px;
    max-height: 468px;
}

@media (max-width: 500px) {
    #colophon #pop-up .modal-content .modal-body .img-fluid {
        max-width: unset;
        max-height: unset;
    }
}

#colophon #pop-up .modal-content .modal-body .img-fluid img {
    object-fit: cover;
    max-width: 468px !important;
    max-height: 468px !important;
}

@media (max-width: 500px) {
    #colophon #pop-up .modal-content .modal-body .img-fluid img {
        max-width: unset;
        max-height: unset;
        width: 100%;
        height: 100%;
    }
}

#colophon #pop-up .modal-content .modal-footer {
    border: none;
    justify-content: center;
}

#colophon #pop-up .modal-content .modal-footer #link_modal {
    background: var(--primary);
    letter-spacing: -.32px;
    text-align: center;
    padding: 1rem 2rem;
    border-radius: 50px;
}

#colophon #pop-up .modal-content .modal-footer #link_modal a {
    color: var(--font);
    font-size: 1rem;
    letter-spacing: .0025rem;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    text-decoration: none;
}

#colophon #pop-up .modal-content .modal-footer #link_modal a:hover {
    text-decoration: underline;
}

#colophon #pop-up .modal-content .modal-footer #link_modal a svg {
    height: 20px;
    fill: var(--font);
}

#colophon #modal_search {
    color: var(--font);
    opacity: 1;
}

#colophon #modal_search .modal-content {
    background: var(--background);
    border: none;
}

#colophon #modal_search .modal-content .modal-header {
    border: none;
}

#colophon #modal_search .modal-content .modal-header .modal-title {
    font-family: "Marcellus", serif;
    font-weight: 400;
}

#colophon #modal_search .modal-content .modal-header .btn-close {
    margin: -.5rem -.5rem -.5rem 0.5rem;
}

#colophon #modal_search .modal-content .modal-body {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
}

#colophon #modal_search .modal-content .modal-body #search_widget {
    width: 100%;
}

#colophon #modal_search .modal-content .modal-body #search_widget form input {
    height: 100%;
    width: 100%;
    color: var(--font);
    border: solid 1px var(--primary);
}

#colophon #modal_search .modal-content .modal-body #search_widget form input::placeholder {
    color: var(--font);
}

#colophon #modal_search .modal-content .modal-body #search_widget form input:focus {
    outline: none;
    background: inherit;
}

#colophon #modal_search .modal-content .modal-body #search_widget form button {
    display: flex;
    align-items: center;
    background: var(--primary);
    color: var(--font);
    letter-spacing: -.32px;
    text-align: center;
    border: none;
    height: 100%;
    bottom: 0;
    right: 0;
}

#colophon #modal_search .modal-content .modal-body #search_widget form button span {
    display: inline-block !important;
}

#colophon #modal_search .modal-content .modal-body #search_widget form button .search:hover {
    color: var(--font);
}

#colophon #modal_search .modal-content .modal-body #search_widget form input:focus + button .search {
    color: var(--font);
}

#colophon #footer-top #newsletter {
    padding-top: 7rem;
    padding-bottom: 3.5rem;
}

@media (max-width: 991px) {
    #colophon #footer-top #newsletter {
        padding-top: 3rem;
    }
}

#colophon #footer-top #newsletter [id*="blockEmailSubscription_"] {
    background: var(--secondary);
    color: var(--font_header);
    border: none !important;
}

@media (max-width: 768px) {
    #colophon #footer-top #newsletter [id*="blockEmailSubscription_"] #footer-newsletter {
        text-align: center !important;
    }
}

#colophon #footer-top #newsletter [id*="blockEmailSubscription_"] #footer-newsletter .alert {
    position: absolute;
    top: -100%;
    right: 0;
    width: 100%;
}

@media (max-width: 968px) {
    #colophon #footer-top #newsletter [id*="blockEmailSubscription_"] #footer-newsletter .alert {
        top: -52%;
    }
}

@media (max-width: 340px) {
    #colophon #footer-top #newsletter [id*="blockEmailSubscription_"] #footer-newsletter .alert {
        padding-left: 0;
        padding-right: 0;
    }
}

#colophon #footer-top #newsletter [id*="blockEmailSubscription_"] #footer-newsletter label {
    font-size: 1rem;
    letter-spacing: -0.34px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

#colophon #footer-top #newsletter [id*="blockEmailSubscription_"] #footer-newsletter #mail_news {
    background: var(--font_header);
    margin-left: 2rem;
    border-radius: 30px;
    border: 0;
    font-weight: 600;
    font-size: 1.0625rem;
    padding: 10px 10px 10px 20px;
    display: inline;
    position: relative;
}

@media (max-width: 991px) {
    #colophon #footer-top #newsletter [id*="blockEmailSubscription_"] #footer-newsletter #mail_news {
        margin-left: 0;
    }
}

#colophon #footer-top #newsletter [id*="blockEmailSubscription_"] #footer-newsletter #mail_news input[type="email"] {
    border: none;
    color: var(--font);
    width: 90%;
}

#colophon #footer-top #newsletter [id*="blockEmailSubscription_"] #footer-newsletter #mail_news input[type="email"]::placeholder {
    color: var(--font);
    font-family: "IBM Plex Sans", sans-serif;
}

#colophon #footer-top #newsletter [id*="blockEmailSubscription_"] #footer-newsletter #mail_news input[type="email"]:focus {
    padding: 11px;
}

#colophon #footer-top #newsletter [id*="blockEmailSubscription_"] #footer-newsletter button {
    display: block;
    background: transparent;
    border: none;
    bottom: 1rem;
    right: 1rem;
}

#colophon #footer-top #newsletter [id*="blockEmailSubscription_"] #footer-newsletter button svg {
    height: 20px;
    fill: var(--font);
}

#colophon #footer-top #newsletter [id*="blockEmailSubscription_"] #footer-newsletter #gdpr_consent {
    margin-left: -15px;
    opacity: .4;
}

#colophon #footer-top #newsletter [id*="blockEmailSubscription_"] #footer-newsletter #gdpr_consent label {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
}

#colophon #footer-top #newsletter [id*="blockEmailSubscription_"] #footer-newsletter #gdpr_consent input[type=checkbox] + span {
    border-color: var(--font_secondary);
}

#colophon #footer-top #newsletter [id*="blockEmailSubscription_"] #footer-newsletter #gdpr_consent .material-icons {
    color: var(--font_secondary);
}

@media (max-width: 1199px) {
    #colophon #footer-top #newsletter #rsf {
        align-self: center !important;
    }
}

@media (max-width: 450px) {
    #colophon #footer-top #newsletter #rsf {
        justify-content: center !important;
    }
}

#colophon #footer-top #newsletter #rsf .reseaux_sociaux svg {
    fill: var(--font_header);
    height: 30px;
    width: 30px;
    margin-left: 15px;
}

@media (max-width: 991px) {
    #colophon #footer-top #newsletter #rsf .reseaux_sociaux svg {
        margin-left: 0;
        margin-right: 15px;
    }
}

@media (max-width: 450px) {
    #colophon #footer-top #newsletter #rsf .reseaux_sociaux {
        margin-bottom: 1rem;
    }
}

#colophon #footer-top hr {
    background-color: var(--information);
    opacity: .35;
}

#colophon #footer-middle {
    padding-top: 4rem;
    padding-bottom: 4rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
}

#colophon #footer-middle #logof {
    color: var(--font_secondary);
    text-decoration: none;
    font-size: 2rem;
}

#colophon #footer-middle #logof:hover {
    text-decoration: underline;
}

#colophon #footer-middle #info_footer-middle div {
    margin-bottom: .25rem;
}

#colophon #footer-middle #info_footer-middle a[href^="mailto:"] {
    color: var(--primary);
}

#colophon #footer-middle [class*="menu-pied-de-page"] ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

#colophon #footer-middle [class*="menu-pied-de-page"] ul li {
    text-align: left;
}

#colophon #footer-middle [class*="menu-pied-de-page"] ul li a {
    color: var(--font_header);
    text-decoration: none;
    letter-spacing: -0.34px;
    transition: ease all 300ms;
}

#colophon #footer-middle [class*="menu-pied-de-page"] ul li a:hover {
    color: var(--primary);
    text-decoration: underline;
}

#colophon hr {
    background-color: var(--information);
    opacity: .35;
}

#colophon #footer-bottom {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
}

#colophon #footer-bottom .container .is-information {
    align-self: center;
}

@media (max-width: 767px) {
    #colophon #footer-bottom .container .is-information {
        align-self: unset;
        text-align: center;
    }
}

#colophon #footer-bottom a {
    color: var(--information);
    text-decoration: none;
}

#colophon #footer-bottom a:hover {
    text-decoration: underline;
}

@media (max-width: 767px) {
    #colophon #footer-bottom a {
        display: block;
    }
}

#colophon #footer-bottom [class*="menu-mentions-legales"] ul {
    display: flex;
    list-style: none;
    margin: 0;
}

@media (max-width: 1201px) {
    #colophon #footer-bottom [class*="menu-mentions-legales"] ul {
        justify-content: space-around;
        margin-bottom: 2rem;
    }
}

@media (max-width: 767px) {
    #colophon #footer-bottom [class*="menu-mentions-legales"] ul {
        padding-left: 0;
        text-align: center;
    }
}

@media (max-width: 650px) {
    #colophon #footer-bottom [class*="menu-mentions-legales"] ul {
        flex-wrap: wrap;
    }
}

#colophon #footer-bottom [class*="menu-mentions-legales"] ul li {
    margin-left: 2rem;
}

@media (max-width: 1201px) {
    #colophon #footer-bottom [class*="menu-mentions-legales"] ul li {
        margin-left: 0;
    }
}

@media (max-width: 650px) {
    #colophon #footer-bottom [class*="menu-mentions-legales"] ul li {
        flex: 50%;
    }
}

#colophon #footer-bottom [class*="menu-mentions-legales"] ul li:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)) {
    display: none;
}

#ui-id-1 {
    z-index: 9999;
}

#ui-id-1 li {
    font-size: 1rem;
}

#ui-id-1 li a {
    color: var(--font);
}

#ui-id-1 li a:hover {
    color: var(--ternary);
}

#carrousel {
    margin-bottom: 0;
    min-height: 700px;
  /* Surcharge du slick */
}

@media (max-width: 991px) {
    #carrousel {
        min-height: unset;
    }
}

@media (max-width: 650px) {
    #carrousel {
        min-height: 300px;
        padding-bottom: 0;
    }
}

#carrousel .slide .card {
    width: 50%;
    background: transparent;
    color: var(--font);
    border: none;
    z-index: 1;
    min-height: 733px;
    top: 50px;
    left: 330px;
    box-shadow: none;
}

@media (max-width: 1375px) {
    #carrousel .slide .card {
        width: 50%;
    }
}

@media (max-width: 991px) {
    #carrousel .slide .card {
        width: 100%;
        left: 0;
        min-height: unset;
    }
}

@media (max-width: 650px) {
    #carrousel .slide .card {
        position: absolute;
        top: 0;
        min-height: 300px;
    }
}

#carrousel .slide .card .card-body {
    padding-top: 160px;
    padding-left: 160px;
}

@media (max-width: 1600px) {
    #carrousel .slide .card .card-body {
        padding-top: 4rem;
        padding-left: 8rem;
    }
}

@media (max-width: 1375px) {
    #carrousel .slide .card .card-body {
        padding-left: 6rem;
    }
}

@media (max-width: 1050px) {
    #carrousel .slide .card .card-body {
        padding-left: 2rem;
    }
}

@media (max-width: 991px) {
    #carrousel .slide .card .card-body {
        padding: 2rem 0 2rem 2rem;
    }
}

@media (max-width: 650px) {
    #carrousel .slide .card .card-body {
        padding: .5rem .5rem 1rem;
    }
}

#carrousel .slide .card .card-body .card-sub_title {
    color: var(--primary);
    font-size: 2rem;
    font-family: "Marcellus", serif;
    font-weight: 400;
    position: relative;
    letter-spacing: .025rem;
}

#carrousel .slide .card .card-body .card-sub_title::before {
    content: "";
    position: absolute;
    border-top: 1px solid var(--font_secondary);
    width: 5.938rem;
    margin: 0 1rem;
    transform: translateY(1.25rem) translateX(-8.25rem);
    opacity: .35;
}

@media (max-width: 1375px) {
    #carrousel .slide .card .card-body .card-sub_title::before {
        display: none;
    }
}

#carrousel .slide .card .card-body .card-title {
    color: var(--font_secondary);
    font-size: 4.25rem;
    letter-spacing: -2.6px;
    width: 100%;
    margin-bottom: 3.5rem;
    margin-top: 1.5rem;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    line-height: 4.25rem;
}

@media (max-width: 1150px) {
    #carrousel .slide .card .card-body .card-title {
        font-size: 3.5rem;
    }
}

@media (max-width: 991px) {
    #carrousel .slide .card .card-body .card-title {
        font-size: 3rem;
        letter-spacing: -2px;
        margin: 0.25rem 0 2rem;
    }
}

@media (max-width: 650px) {
    #carrousel .slide .card .card-body .card-title {
        font-size: 2rem;
    }
}

#carrousel .slide .card .card-body .card-link {
    margin-bottom: 3rem;
}

@media (max-width: 650px) {
    #carrousel .slide .card .card-body .card-link {
        text-align: center;
    }
}

#carrousel .slide .card .card-body .card-link .link {
    background: var(--primary);
    color: var(--font);
    border-radius: 50px;
    border: 0;
    font-weight: 600;
    font-size: 1rem;
    padding: 20px 45px;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    transition: ease all 300ms;
}

@media (max-width: 650px) {
    #carrousel .slide .card .card-body .card-link .link {
        padding: 20px 30px;
        font-size: .75rem;
    }
}

#carrousel .slide .card .card-body .card-link .link:hover {
    background: var(--font_secondary);
}

#carrousel .slide .card .card-body .card-link .link svg {
    width: 20px;
    margin: -3px 4px 0 0;
}

#carrousel .slide .slide_image {
    height: 100%;
    height: 700px;
    background: #000000;
}

@media (max-width: 1375px) {
    #carrousel .slide .slide_image {
        width: 50%;
    }
}

@media (max-width: 991px) {
    #carrousel .slide .slide_image {
        width: 100%;
        height: 100%;
        min-height: unset;
    }
}

@media (max-width: 650px) {
    #carrousel .slide .slide_image {
        min-height: 300px;
        height: 100%;
    }

    #carrousel .slide .slide_image img:not(.filter_on) {
        filter: opacity(50%);
    }
}

#carrousel .slide .slide_image img {
    object-fit: cover;
}

#carrousel .slide .slide_image img.filter_on {
    filter: opacity(50%);
    -webkit-filter: opacity(50%);
}

#carrousel .slick-track {
    display: flex !important;
}

#carrousel .slick-slide {
    height: inherit !important;
}

#carrousel button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

#carrousel button.slide-arrow {
    position: absolute;
    top: 50%;
    margin-top: -15px;
    z-index: 1;
}

@media (max-width: 800px) {
    #carrousel button.slide-arrow {
        display: none !important;
    }
}

#carrousel button.slide-arrow.prev-arrow, #carrousel button.slide-arrow.next-arrow {
    border: 1px solid var(--primary);
    background: var(--primary);
    border-radius: 50%;
    height: 65px;
    width: 65px;
    transition: ease all 300ms;
}

#carrousel button.slide-arrow.prev-arrow svg, #carrousel button.slide-arrow.next-arrow svg {
    height: 20px;
    width: 20px;
}

#carrousel button.slide-arrow.prev-arrow:hover, #carrousel button.slide-arrow.next-arrow:hover {
    border: 1px solid var(--ternary);
    background: var(--ternary);
}

#carrousel button.slide-arrow.prev-arrow {
    left: 80px;
}

#carrousel button.slide-arrow.prev-arrow svg {
    transform: rotate(180deg);
}

#carrousel button.slide-arrow.next-arrow {
    right: 80px;
}

#carrousel .slick-dots {
    bottom: 10px;
    left: 330px;
    width: 40%;
    text-align: left;
    margin-bottom: 10px;
    margin-left: 170px;
}

@media (max-width: 991px) {
    #carrousel .slick-dots {
        width: 100%;
        text-align: center;
        margin: 0 0 10px;
        left: 0;
    }
}

#carrousel .slick-dots li {
    height: 10px;
    width: 10px;
    margin: 0 17px 0 0;
}

#carrousel .slick-dots li button {
    border: 2px solid var(--font_secondary);
    border-radius: 50%;
    height: 10px;
    width: 10px;
}

#carrousel .slick-dots li button:focus {
    background: var(--ternary);
}

#carrousel .slick-dots li button:before {
    color: transparent;
}

#carrousel .slick-dots li button[aria-selected] {
    border: 2px solid var(--ternary);
    background: var(--ternary);
}

#actualites {
    background: var(--hover);
    color: var(--font);
    padding: 9rem 8rem 9.5rem 8rem;
}

@media (max-width: 991px) {
    #actualites {
        padding: 2rem;
    }
}

#actualites h1 {
    margin-bottom: 3.5rem;
    font-size: 3rem;
    letter-spacing: -0.0125rem;
    font-family: "Marcellus", serif;
    font-weight: 400;
    color: var(--ternary);
}

@media (max-width: 991px) {
    #actualites h1 {
        margin-bottom: 1.5rem;
        font-size: 2.5rem;
        letter-spacing: -0.05rem;
    }
}

#actualites .actualite {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

@media (max-width: 1400px) {
    #actualites .actualite {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media (max-width: 1275px) {
    #actualites .actualite {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media (max-width: 991px) {
    #actualites .actualite {
        padding-left: 0;
        padding-right: 0;
    }
}

#actualites .actualite .card {
    border: none;
}

#actualites .actualite .card .img-fluid img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

#actualites .actualite .card .card-body {
    padding: 2.8rem 2.8rem 3.75rem 2.8rem;
    background: var(--background);
    transition: ease all 300ms;
}

@media (max-width: 991px) {
    #actualites .actualite .card .card-body {
        padding: 1.8rem 1.5rem 2.75rem;
        min-height: 200px;
    }
}

#actualites .actualite .card .card-body .card-title {
    font-size: .938rem;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

#actualites .actualite .card .card-body .card-title a {
    color: inherit;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
}

#actualites .actualite .card .card-body .card-text {
    letter-spacing: -0.36px;
    font-size: 1.125rem;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    text-align: left;
    margin-bottom: 1.75rem;
}

@media (max-width: 991px) {
    #actualites .actualite .card .card-body .card-text {
        letter-spacing: inherit;
        font-family: "IBM Plex Sans", sans-serif;
        font-size: 1rem;
        margin-bottom: 2rem;
    }
}

#actualites .actualite .card .card-body .actu_more {
    bottom: 40px;
    right: 30px;
}

#actualites .actualite .card .card-body .actu_more a {
    border-radius: 100%;
    padding: 22px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

#actualites .actualite .card .card-body .actu_more a svg {
    height: 1.25rem;
}

#actualites .actualite .card:hover .card-body {
    background: var(--secondary);
}

#actualites .actualite .card:hover .card-body .card-title a {
    color: var(--primary);
}

#actualites .actualite .card:hover .card-body .card-text {
    color: var(--font_secondary);
}

#actualites .actualite .card:hover .card-body .actu_more a {
    border: 1px solid var(--hover);
}

#actualites .actualite .card:hover .card-body .actu_more a svg {
    fill: var(--font_secondary);
}

#actualites .actualite:nth-child(2) {
    position: relative;
}

#actualites .actualite:nth-child(2) .card::before {
    content: "";
    position: absolute;
    border-left: 1px solid var(--information);
    height: 2.5rem;
    margin: 0 1rem;
    transform: translateY(-40px);
    left: 47%;
    opacity: .35;
}

@media (max-width: 1200px) {
    #actualites .actualite:nth-child(2) .card::before {
        display: none;
    }
}

#actualites #all_actualite {
    padding-right: 1.75rem;
}

@media (max-width: 1200px) {
    #actualites #all_actualite {
        padding-right: 0;
    }
}

#actualites #all_actualite a {
    color: inherit;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    text-decoration: underline;
}

#actualites #all_actualite::before {
    content: url(../img/down.svg);
    width: 13px;
    height: 7px;
    transform: rotate(-90deg) translateX(-9px);
    display: inline-block;
    margin: -12px 10px 10px;
}

#actualites .slick-track {
    display: flex !important;
}

#actualites .slick-slide {
    height: inherit !important;
}

#actualites .slick-dots {
    bottom: 0;
}

#actualites .slick-dots li {
    height: 10px;
    width: 10px;
}

#actualites .slick-dots li button {
    border: 2px solid var(--secondary);
    border-radius: 50%;
    height: 10px;
    width: 10px;
}

#actualites .slick-dots li button:focus {
    background: var(--primary);
}

#actualites .slick-dots li button:before {
    color: transparent;
}

#actualites .slick-dots li button[aria-selected] {
    border: 2px solid var(--primary);
    background: var(--primary);
}

#coordonnees #background {
    height: 800px;
    z-index: 0;
}

@media (max-width: 991px) {
    #coordonnees #background {
        display: none;
    }
}

#coordonnees #background .bulle-nom {
    color: var(--font);
    font-size: .9rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
}

#coordonnees #all_coordonnees {
    background: var(--primary);
    color: var(--font);
    padding: 4.375rem 5rem 5rem;
    max-width: 35rem;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    z-index: 1;
}

#coordonnees #all_coordonnees.absolute {
    top: 90px;
    right: 200px;
    position: absolute;
    z-index: 1;
}

@media (max-width: 1400px) {
    #coordonnees #all_coordonnees.absolute {
        right: 100px;
    }
}

@media (max-width: 1180px) {
    #coordonnees #all_coordonnees.absolute {
        top: 50px;
    }
}

@media (max-width: 1080px) {
    #coordonnees #all_coordonnees.absolute {
        position: relative;
        top: 0;
        right: 0;
        margin: 0;
        max-width: inherit;
    }
}

#coordonnees #all_coordonnees.relative {
    margin: auto;
    position: relative;
    top: 50px;
}

@media (max-width: 1080px) {
    #coordonnees #all_coordonnees.relative {
        top: 0;
    }
}

@media (max-width: 650px) {
    #coordonnees #all_coordonnees {
        padding: 1.375rem 1.25rem 3rem;
        max-width: 26rem;
        margin-bottom: 1rem;
    }
}

#coordonnees #all_coordonnees #title_coordonnees {
    color: var(--font);
    font-size: 2.063rem;
    font-family: "Marcellus", serif;
    font-weight: 400;
}

#coordonnees #all_coordonnees #info_coordonnees {
    padding-bottom: 3.125rem;
}

#coordonnees #all_coordonnees #info_coordonnees .tel {
    letter-spacing: -0.88px;
    font-size: 1.375rem;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

#coordonnees #all_coordonnees #info_coordonnees .info_tel,
    #coordonnees #all_coordonnees #info_coordonnees .hours,
    #coordonnees #all_coordonnees #info_coordonnees .contact_form {
    letter-spacing: -0.32px;
}

#coordonnees #all_coordonnees #info_coordonnees .info_tel a,
      #coordonnees #all_coordonnees #info_coordonnees .hours a,
      #coordonnees #all_coordonnees #info_coordonnees .contact_form a {
    color: var(--font);
}

#coordonnees #all_coordonnees #info_coordonnees .svg {
    width: 35px;
    text-align: center;
}

#coordonnees #all_coordonnees #info_coordonnees .svg svg {
    fill: var(--font_secondary);
    height: 1.25rem;
}

#coordonnees #all_coordonnees .slick-track {
    display: flex !important;
}

#coordonnees #all_coordonnees .slick-slide {
    height: inherit !important;
}

#coordonnees #all_coordonnees .slick-dots {
    bottom: 0;
}

@media (max-width: 650px) {
    #coordonnees #all_coordonnees .slick-dots {
        width: 95%;
    }
}

#coordonnees #all_coordonnees .slick-dots li {
    height: 10px;
    width: 10px;
}

#coordonnees #all_coordonnees .slick-dots li button {
    border: 2px solid var(--secondary);
    border-radius: 50%;
    height: 10px;
    width: 10px;
}

#coordonnees #all_coordonnees .slick-dots li button:focus {
    background: var(--background);
}

#coordonnees #all_coordonnees .slick-dots li button:before {
    color: transparent;
}

#coordonnees #all_coordonnees .slick-dots li button[aria-selected] {
    border: 2px solid var(--background);
    background: var(--background);
}

#coordonnees #all_coordonnees hr {
    background-color: var(--font_secondary);
    opacity: .35;
}

#coordonnees #all_coordonnees #info_rencontre {
    padding-top: 3.125rem;
}

#coordonnees #all_coordonnees #info_rencontre #title_rencontre {
    letter-spacing: -2px;
    font-size: 3.125rem;
    font-family: "Inter", sans-serif;
    font-weight: 300;
}

@media (max-width: 450px) {
    #coordonnees #all_coordonnees #info_rencontre #title_rencontre {
        font-size: 2rem;
    }
}

#coordonnees #all_coordonnees #info_rencontre #description_rencontre {
    letter-spacing: -0.32px;
}

#coordonnees #all_coordonnees #info_rencontre #btn_rencontre a {
    background: var(--secondary);
    color: var(--font_secondary);
    text-decoration: none;
    border-radius: 50px;
    border: 0;
    font-weight: 600;
    font-size: 1.0625rem;
    padding: 25px 45px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

@media (max-width: 450px) {
    #coordonnees #all_coordonnees #info_rencontre #btn_rencontre a {
        padding: 20px;
    }
}

#coordonnees #all_coordonnees #info_rencontre #btn_rencontre a svg {
    height: 20px;
    fill: var(--primary);
}

#coordonnees #all_coordonnees #info_rencontre #btn_rencontre a:hover {
    text-decoration: underline;
}

#temoignage {
    background: var(--background);
    color: var(--font_secondary);
    padding: 10.313rem 22.25rem;
}

@media (max-width: 1600px) {
    #temoignage {
        padding: 10.313rem 16.25rem;
    }
}

@media (max-width: 1300px) {
    #temoignage {
        padding: 10.313rem 10.25rem;
    }
}

@media (max-width: 1000px) {
    #temoignage {
        padding: 6rem 6.25rem;
    }
}

@media (max-width: 800px) {
    #temoignage {
        padding: 6rem 2.25rem;
    }
}

@media (max-width: 650px) {
    #temoignage {
        padding: 6rem 1.25rem;
    }
}

#temoignage .temoignage_slider .informations {
    background: var(--secondary);
    padding: 4.688rem 14.375rem 5.625rem;
    height: 430px;
}

@media (max-width: 1645px) {
    #temoignage .temoignage_slider .informations {
        padding: 4.688rem 6.375rem 5.625rem;
    }
}

@media (max-width: 1000px) {
    #temoignage .temoignage_slider .informations {
        padding: 4.688rem 2.375rem 5.625rem;
    }
}

@media (max-width: 650px) {
    #temoignage .temoignage_slider .informations {
        padding: 4.688rem 1.375rem 5.625rem;
    }
}

@media (max-width: 500px) {
    #temoignage .temoignage_slider .informations {
        height: 530px;
    }
}

#temoignage .temoignage_slider .informations .temoignage_svg svg {
    height: 33px;
    fill: var(--primary);
    margin-bottom: 1.363rem;
}

#temoignage .temoignage_slider .informations h1 {
    font-size: 3rem;
    letter-spacing: -0.0125rem;
    margin: 0 0 1.263rem;
    font-family: "Marcellus", serif;
    font-weight: 400;
}

@media (max-width: 400px) {
    #temoignage .temoignage_slider .informations h1 {
        font-size: 3rem;
    }
}

#temoignage .temoignage_slider .informations .temoignage_content {
    letter-spacing: -0.36px;
    font-size: 1.125rem;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 500;
}

#temoignage .temoignage_slider .temoignage_auteur {
    background: var(--primary);
    color: var(--secondary);
    text-align: center;
    padding: 1.25rem 0;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

#temoignage .temoignage_slider .slick-track {
    display: flex !important;
}

#temoignage .temoignage_slider .slick-slide {
    height: inherit !important;
}

#temoignage .temoignage_slider .slick-dots {
    display: none !important;
}

@media (max-width: 800px) {
    #temoignage .temoignage_slider .slick-dots {
        display: block !important;
        bottom: -1.8rem;
    }

    #temoignage .temoignage_slider .slick-dots li {
        height: 10px;
        width: 10px;
    }

    #temoignage .temoignage_slider .slick-dots li button {
        border: 2px solid var(--secondary);
        border-radius: 50%;
        height: 14px;
        width: 14px;
    }

    #temoignage .temoignage_slider .slick-dots li button:focus {
        background: var(--primary);
    }

    #temoignage .temoignage_slider .slick-dots li button:before {
        color: transparent;
    }

    #temoignage .temoignage_slider .slick-dots li button[aria-selected] {
        border: 2px solid var(--primary);
        background: var(--primary);
    }
}

#temoignage .temoignage_slider button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

#temoignage .temoignage_slider button.slide-arrow {
    position: absolute;
    top: 50%;
    margin-top: -15px;
}

@media (max-width: 800px) {
    #temoignage .temoignage_slider button.slide-arrow {
        display: none !important;
    }
}

#temoignage .temoignage_slider button.slide-arrow.prev-arrow, #temoignage .temoignage_slider button.slide-arrow.next-arrow {
    border: 1px solid var(--information);
    border-radius: 50%;
    height: 65px;
    width: 65px;
    transition: ease all 300ms;
}

#temoignage .temoignage_slider button.slide-arrow.prev-arrow svg, #temoignage .temoignage_slider button.slide-arrow.next-arrow svg {
    height: 20px;
    width: 20px;
}

#temoignage .temoignage_slider button.slide-arrow.prev-arrow:hover, #temoignage .temoignage_slider button.slide-arrow.next-arrow:hover {
    border: 1px solid var(--primary);
    background: var(--primary);
}

#temoignage .temoignage_slider button.slide-arrow.prev-arrow {
    left: -100px;
}

@media (max-width: 1000px) {
    #temoignage .temoignage_slider button.slide-arrow.prev-arrow {
        left: -85px;
    }
}

#temoignage .temoignage_slider button.slide-arrow.prev-arrow svg {
    transform: rotate(180deg);
}

#temoignage .temoignage_slider button.slide-arrow.next-arrow {
    right: -100px;
}

@media (max-width: 1000px) {
    #temoignage .temoignage_slider button.slide-arrow.next-arrow {
        right: -85px;
    }
}

#qui_sommes_nous {
    color: var(--font);
    height: 700px;
    margin-top: 11rem;
    margin-bottom: 11rem;
}

@media (max-width: 668px) {
    #qui_sommes_nous {
        height: 500px;
    }
}

#qui_sommes_nous #background_gauche {
    background: var(--secondary);
}

#qui_sommes_nous #background_gauche img {
    object-fit: cover;
}

#qui_sommes_nous #infos {
    padding: 7.938rem 12rem 0;
}

@media (max-width: 1400px) {
    #qui_sommes_nous #infos {
        padding: 6rem 8rem 0;
    }
}

@media (max-width: 890px) {
    #qui_sommes_nous #infos {
        padding: 2rem 4rem 0;
    }
}

@media (max-width: 668px) {
    #qui_sommes_nous #infos {
        padding: 1rem 1rem 0;
    }
}

#qui_sommes_nous #infos h1 {
    font-family: "Marcellus", serif;
    font-weight: 400;
    font-size: 2.063rem;
    color: var(--ternary);
}

#qui_sommes_nous #infos #slogan {
    font-size: 3rem;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    letter-spacing: -2px;
}

@media (max-width: 668px) {
    #qui_sommes_nous #infos #slogan {
        font-size: 2rem;
    }
}

#qui_sommes_nous #infos #description {
    letter-spacing: -0.32px;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
}

@media (max-width: 550px) {
    #qui_sommes_nous #infos #qsn_link {
        text-align: center;
    }
}

#qui_sommes_nous #infos #qsn_link #link_more {
    background: var(--ternary);
    color: var(--font_secondary);
    border-radius: 50px;
    border: 0;
    font-weight: 600;
    font-size: 1.0625rem;
    padding: 20px 50px;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    transition: ease all 300ms;
}

#qui_sommes_nous #infos #qsn_link #link_more:hover {
    background: var(--secondary);
}

#produits_unes {
    background: var(--background);
    margin-top: 7.5rem;
}

#produits_unes h2 {
    font-size: 3rem;
    letter-spacing: -0.0125rem;
    color: var(--ternary);
    font-family: "Marcellus", serif;
    font-weight: 400;
    position: relative;
    margin-bottom: 0;
}

#produits_unes h2::after {
    content: "";
    position: absolute;
    border-top: 1px solid var(--information);
    width: 5.625rem;
    margin: 0 1rem;
    transform: translateY(95px);
    left: 47%;
    opacity: .35;
}

@media (max-width: 1200px) {
    #produits_unes h2::after {
        display: none;
    }
}

#produits_unes .products {
    padding-left: 11rem;
    padding-right: 11rem;
    margin-top: 6rem;
    margin-bottom: 7rem;
}

@media (max-width: 1250px) {
    #produits_unes .products {
        padding-left: 9rem;
        padding-right: 9rem;
    }
}

@media (max-width: 991px) {
    #produits_unes .products {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}

@media (max-width: 500px) {
    #produits_unes .products {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

@media (max-width: 400px) {
    #produits_unes .products {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

#produits_unes .products .product {
    width: auto;
    min-width: unset;
}

#produits_unes .products .product .product-miniature {
    font-family: "Inter", sans-serif;
}

#produits_unes .products .product .product-miniature .thumbnail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    height: unset;
    width: unset;
    box-shadow: none;
}

#produits_unes .products .product .product-miniature .thumbnail-container .product-thumbnail {
    position: relative;
    height: unset;
}

#produits_unes .products .product .product-miniature .thumbnail-container .product-thumbnail img {
    object-fit: cover;
    position: relative;
    height: 100%;
    width: 100%;
}

@media (max-width: 991px) {
    #produits_unes .products .product .product-miniature .thumbnail-container .product-thumbnail img {
        max-height: 300px;
        max-width: 300px;
    }
}

#produits_unes .products .product .product-miniature .thumbnail-container .product-thumbnail a.cercle {
    position: absolute;
    top: 50%;
    left: 50%;
    background: var(--secondary);
    font-family: "Inter", sans-serif;
    font-weight: 500;
    transform: translate(-50%, -50%);
    color: var(--font_secondary);
    text-decoration: none;
    font-size: 1rem;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    visibility: hidden;
    opacity: 0;
    transition: visibility .5s, opacity .5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

@media (max-width: 1520px) {
    #produits_unes .products .product .product-miniature .thumbnail-container .product-thumbnail a.cercle {
        width: 200px;
        height: 200px;
    }
}

@media (max-width: 1280px) {
    #produits_unes .products .product .product-miniature .thumbnail-container .product-thumbnail a.cercle {
        width: 180px;
        height: 180px;
    }
}

@media (max-width: 1199px) {
    #produits_unes .products .product .product-miniature .thumbnail-container .product-thumbnail a.cercle {
        width: 200px;
        height: 200px;
    }
}

@media (max-width: 991px) {
    #produits_unes .products .product .product-miniature .thumbnail-container .product-thumbnail a.cercle {
        width: 250px;
        height: 250px;
    }
}

#produits_unes .products .product .product-miniature .thumbnail-container .product-thumbnail a.cercle:hover {
    text-decoration: underline;
}

#produits_unes .products .product .product-miniature .thumbnail-container .product-thumbnail a.cercle svg {
    fill: var(--font_secondary);
    width: 13px;
    height: 7px;
    transform: rotate(-90deg) translateX(-9px);
    display: inline-block;
    margin: -8px 0 10px;
}

#produits_unes .products .product .product-miniature .thumbnail-container .product-thumbnail:hover a.cercle {
    visibility: visible;
    opacity: 1;
}

#produits_unes .products .product .product-miniature .thumbnail-container .product-description {
    position: relative;
    text-align: center;
    z-index: unset;
    background: transparent;
    width: unset;
    height: unset;
    bottom: unset;
}

#produits_unes .products .product .product-miniature .thumbnail-container .product-description::after {
    content: none;
}

#produits_unes .products .product .product-miniature .thumbnail-container .product-description .product-title {
    font-weight: 600;
    margin-top: 0;
    text-transform: uppercase !important;
    padding: 25px 60px 5px;
    line-height: 1rem;
}

#produits_unes .products .product .product-miniature .thumbnail-container .product-description .product-title a {
    color: var(--font);
    font-size: 1rem;
    text-decoration: none;
}

#produits_unes .products .product .product-miniature .thumbnail-container .product-description .price {
    font-weight: 500;
    color: var(--ternary);
    font-size: 1.125rem;
    letter-spacing: -0.36px;
}

#produits_unes .products .product .product-miniature .thumbnail-container .product-flags li.product-flag.discount {
    background: var(--primary);
    color: var(--font);
}

#produits_unes .products .slick-track {
    display: flex !important;
}

#produits_unes .products .slick-slide {
    height: inherit !important;
}

#produits_unes .products .slick-dots {
    display: none !important;
}

@media (max-width: 800px) {
    #produits_unes .products .slick-dots {
        display: block !important;
        bottom: -1.8rem;
    }

    #produits_unes .products .slick-dots li {
        height: 10px;
        width: 10px;
    }

    #produits_unes .products .slick-dots li button {
        border: 2px solid var(--secondary);
        border-radius: 50%;
        height: 14px;
        width: 14px;
    }

    #produits_unes .products .slick-dots li button:focus {
        background: var(--primary);
    }

    #produits_unes .products .slick-dots li button:before {
        color: transparent;
    }

    #produits_unes .products .slick-dots li button[aria-selected] {
        border: 2px solid var(--primary);
        background: var(--primary);
    }
}

#produits_unes .products button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

#produits_unes .products button.slide-arrow {
    position: absolute;
    top: 50%;
    margin-top: -15px;
}

@media (max-width: 800px) {
    #produits_unes .products button.slide-arrow {
        display: none !important;
    }
}

#produits_unes .products button.slide-arrow.prev-arrow, #produits_unes .products button.slide-arrow.next-arrow {
    border: 1px solid var(--information);
    border-radius: 50%;
    height: 65px;
    width: 65px;
    transition: ease all 300ms;
}

#produits_unes .products button.slide-arrow.prev-arrow svg, #produits_unes .products button.slide-arrow.next-arrow svg {
    height: 20px;
    width: 20px;
}

#produits_unes .products button.slide-arrow.prev-arrow:hover, #produits_unes .products button.slide-arrow.next-arrow:hover {
    border: 1px solid var(--secondary);
    background: var(--secondary);
}

#produits_unes .products button.slide-arrow.prev-arrow:hover svg, #produits_unes .products button.slide-arrow.next-arrow:hover svg {
    fill: var(--font_secondary);
}

#produits_unes .products button.slide-arrow.prev-arrow {
    left: 70px;
    transform: translateY(-50%);
    z-index: 1;
}

@media (max-width: 1250px) {
    #produits_unes .products button.slide-arrow.prev-arrow {
        left: 60px;
        transform: translateY(-100%);
    }
}

@media (max-width: 991px) {
    #produits_unes .products button.slide-arrow.prev-arrow {
        left: 30px;
        transform: translateY(-50%);
    }
}

#produits_unes .products button.slide-arrow.prev-arrow svg {
    transform: rotate(180deg);
}

#produits_unes .products button.slide-arrow.next-arrow {
    right: 70px;
    transform: translateY(-50%);
    z-index: 1;
}

@media (max-width: 1250px) {
    #produits_unes .products button.slide-arrow.next-arrow {
        right: 60px;
        transform: translateY(-100%);
    }
}

@media (max-width: 991px) {
    #produits_unes .products button.slide-arrow.next-arrow {
        right: 30px;
        transform: translateY(-50%);
    }
}

#produits_unes #all_produits_unes a {
    background: var(--ternary);
    color: var(--font_secondary);
    border-radius: 50px;
    border: 0;
    font-size: 1rem;
    padding: 20px 45px;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -0.32px;
    clear: none;
    margin-top: 0;
    margin-bottom: 0;
    float: none !important;
}

#produits_unes #all_produits_unes a svg {
    width: 20px;
    fill: var(--font_secondary);
}

#produits_unes #all_produits_unes a:hover {
    background: var(--secondary);
}

#block-reassurance {
    background: var(--secondary);
    color: var(--font_secondary);
    font-family: "Inter", sans-serif;
    font-weight: 600;
    margin-top: 0;
}

#block-reassurance ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding-left: 0;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

@media (max-width: 550px) {
    #block-reassurance ul {
        justify-content: center;
        flex-wrap: wrap;
    }
}

#block-reassurance ul li {
    border-bottom: none;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

#block-reassurance ul li .block-reassurance-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
}

#block-reassurance ul li .block-reassurance-item svg {
    height: 2.188rem;
    width: 2.188rem;
    margin-right: 0;
    margin-bottom: 1rem;
    fill: var(--font_secondary);
}

#block-reassurance ul li .block-reassurance-item span {
    margin-bottom: 0;
    text-transform: uppercase;
}

.block-categories {
    background: var(--background);
    margin-top: 10rem;
    margin-bottom: 8rem;
    padding: 0;
    box-shadow: none;
}

.block-categories .categorie {
    position: relative;
    height: 465px;
    max-width: 490px;
}

.block-categories .categorie .categorie_title {
    position: absolute;
    z-index: 1;
    color: var(--font_secondary);
    font-family: "Marcellus", serif;
    font-weight: 400;
    font-size: 3rem;
    text-decoration: none;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    letter-spacing: -0.0125rem;
}

.block-categories .categorie img {
    object-fit: cover;
    filter: brightness(35%);
    -webkit-filter: brightness(35%);
}

.block-categories .categorie .categorie_content {
    background: var(--ternary);
    display: none;
}

.block-categories .categorie .categorie_content a {
    color: var(--font_secondary);
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: -0.32px;
    text-decoration: none;
    text-transform: uppercase;
}

.block-categories .categorie .categorie_content a:hover {
    text-decoration: underline;
}

.block-categories .categorie .categorie_content a svg {
    fill: var(--font_secondary);
    width: 13px;
    height: 7px;
    transform: rotate(-90deg) translateX(-9px);
    display: inline-block;
    margin: -7px 0 10px;
}

.block-categories .categorie:hover .categorie_title,
    .block-categories .categorie:hover img {
    display: none;
}

.block-categories .categorie:hover .categorie_content {
    display: flex;
}

#content {
    margin-bottom: 0 !important;
    padding: 0;
    box-shadow: unset;
    border: none;
    color: var(--font);
    background: var(--background);
}

#content #content-wrapper #single_actualite .chapeau {
    background: var(--hover);
    padding: 5.5rem;
    margin: 0 0 56px;
    border: 0;
}

@media (max-width: 450px) {
    #content #content-wrapper #single_actualite .chapeau {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

#content #content-wrapper #single_actualite .chapeau h1 {
    font-size: 3.438rem;
    letter-spacing: -1.1px;
    font-family: "Marcellus", serif;
    font-weight: 400;
}

@media (max-width: 450px) {
    #content #content-wrapper #single_actualite .chapeau h1 {
        font-size: 2.438rem;
    }
}

#listing-actu {
    color: var(--font);
    background: var(--background);
}

#listing-actu .chapeau {
    background: var(--hover);
    padding: 3rem;
    margin: -3rem 0 56px;
    border: 0;
}

#listing-actu .chapeau h1 {
    font-size: 3.438rem;
    letter-spacing: -1.1px;
    font-family: "Marcellus", serif;
    font-weight: 400;
}

#listing-actu #all_listing_actu {
    padding: 0 8rem;
    margin-left: 0;
}

@media (max-width: 991px) {
    #listing-actu #all_listing_actu {
        padding: 0 2rem;
        margin: 0;
    }
}

#listing-actu .actualite {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

@media (max-width: 1400px) {
    #listing-actu .actualite {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media (max-width: 1275px) {
    #listing-actu .actualite {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media (max-width: 991px) {
    #listing-actu .actualite {
        padding-left: 0;
        padding-right: 0;
    }
}

#listing-actu .actualite .card {
    border: none;
}

#listing-actu .actualite .card .img-fluid img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

#listing-actu .actualite .card .card-body {
    padding: 2.8rem 2.8rem 3.75rem 2.8rem;
    background: var(--background);
    transition: ease all 300ms;
}

@media (max-width: 991px) {
    #listing-actu .actualite .card .card-body {
        padding: 1.8rem 1.5rem 2.75rem;
        min-height: 200px;
    }
}

#listing-actu .actualite .card .card-body .card-title {
    font-size: .938rem;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

#listing-actu .actualite .card .card-body .card-title a {
    color: inherit;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
}

#listing-actu .actualite .card .card-body .card-text {
    letter-spacing: -0.36px;
    font-size: 1.125rem;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    text-align: left;
}

@media (max-width: 991px) {
    #listing-actu .actualite .card .card-body .card-text {
        letter-spacing: inherit;
        font-family: "IBM Plex Sans", sans-serif;
        font-weight: 400;
        font-size: 1rem;
        margin-bottom: 2rem;
    }
}

#listing-actu .actualite .card .card-body .actu_more {
    bottom: 30px;
    right: 20px;
}

#listing-actu .actualite .card .card-body .actu_more a {
    border-radius: 100%;
    padding: 22px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

#listing-actu .actualite .card .card-body .actu_more a svg {
    height: 1.25rem;
}

#listing-actu .actualite .card:hover .card-body {
    background: var(--secondary);
}

#listing-actu .actualite .card:hover .card-body .card-title a {
    color: var(--primary);
}

#listing-actu .actualite .card:hover .card-body .card-text {
    color: var(--font_secondary);
}

#listing-actu .actualite .card:hover .card-body .actu_more a {
    border: 1px solid var(--hover);
}

#listing-actu .actualite .card:hover .card-body .actu_more a svg {
    fill: var(--font_secondary);
}

#content {
    margin-bottom: 0 !important;
    padding: 0;
    box-shadow: unset;
    border: none;
    color: var(--font);
    background: var(--background);
}

#content #content-wrapper #reservation .chapeau {
    background: var(--hover);
    padding: 5.5rem;
    margin: 0 0 56px;
    border: 0;
}

@media (max-width: 450px) {
    #content #content-wrapper #reservation .chapeau {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

#content #content-wrapper #reservation .chapeau h1 {
    font-size: 3.438rem;
    letter-spacing: -1.1px;
    font-family: "Marcellus", serif;
    font-weight: 400;
}

@media (max-width: 450px) {
    #content #content-wrapper #reservation .chapeau h1 {
        font-size: 2.438rem;
    }
}

#content #content-wrapper #reservation .page-content iframe {
    width: 100%;
    height: 100%;
    min-height: 750px;
}

#left-column {
    background: var(--background);
    padding-left: 2rem;
    padding-right: 5rem;
}

@media (max-width: 1600px) {
    #left-column {
        padding-right: 2rem;
    }
}

@media (max-width: 1200px) {
    #left-column {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media (max-width: 968px) {
    #left-column {
        padding-left: 0;
        padding-right: 0;
    }
}

#left-column .square {
    background: var(--primary);
    width: 75px;
    height: 160px;
    top: 25px;
    left: 0;
}

@media (max-width: 1200px) {
    #left-column .square {
        display: none;
    }
}

#left-column .block-categories {
    display: none;
}

@media (max-width: 968px) {
    #category #content-wrapper {
        padding-left: 0;
        padding-right: 0;
    }
}

#content-wrapper {
    color: var(--font);
    background: var(--background);
}

#content-wrapper .chapeau {
    background: var(--hover);
    padding: 5.5rem;
    margin: 0 0 56px;
    border: 0;
}

@media (max-width: 450px) {
    #content-wrapper .chapeau {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

#content-wrapper .chapeau h1 {
    font-size: 3.438rem;
    letter-spacing: -1.1px;
    font-family: "Marcellus", serif;
    font-weight: 400;
}

@media (max-width: 450px) {
    #content-wrapper .chapeau h1 {
        font-size: 2.438rem;
    }
}

#content-wrapper .page-content {
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    margin-bottom: 3rem;
}

#content-wrapper .page-content.card {
    box-shadow: none;
    border: none;
}

#content-wrapper .page-content img {
    height: 100%;
    object-fit: cover;
}

#content-wrapper .page-content iframe {
    width: 100%;
    height: 100%;
    min-height: 750px;
}

#consult_product {
    color: var(--font);
    background: var(--background);
}

#consult_product .chapeau {
    background: var(--hover);
    padding: 5.5rem;
    margin: 0 0 56px;
    border: 0;
}

@media (max-width: 450px) {
    #consult_product .chapeau {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

#consult_product .chapeau h1 {
    font-size: 3.438rem;
    letter-spacing: -1.1px;
    font-family: "Marcellus", serif;
    font-weight: 400;
}

@media (max-width: 450px) {
    #consult_product .chapeau h1 {
        font-size: 2.438rem;
    }
}

#consult_product #js-product-list-top {
    color: var(--font);
}

@media (max-width: 991px) {
    #consult_product #js-product-list-top .sort-by {
        text-align: left;
        padding: 0;
    }
}

@media (max-width: 768px) {
    #consult_product #js-product-list-top .dropdown {
        width: 100%;
    }
}

#consult_product #js-product-list-top .dropdown .dropdown-menu {
    background: var(--background);
    color: var(--font);
}

#consult_product #js-product-list-top .dropdown .dropdown-menu a {
    text-decoration: none;
}

#consult_product #js-product-list-top .dropdown .dropdown-menu a:hover {
    text-decoration: underline;
    background: var(--ternary);
}

#consult_product #js-product-list-top .filter-button {
    display: none;
}

#consult_product #js-product-list {
    color: var(--font);
}

#consult_product #js-product-list .products {
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
}

@media (max-width: 991px) {
    #consult_product #js-product-list .products {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 768px) {
    #consult_product #js-product-list .products {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 500px) {
    #consult_product #js-product-list .products {
        gap: 1rem;
    }
}

@media (max-width: 450px) {
    #consult_product #js-product-list .products {
        grid-template-columns: repeat(1, 1fr);
    }
}

#consult_product #js-product-list .products .product {
    width: auto;
    min-width: unset;
}

#consult_product #js-product-list .products .product .product-miniature {
    font-family: "Inter", sans-serif;
}

#consult_product #js-product-list .products .product .product-miniature .thumbnail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    height: unset;
    width: unset;
    box-shadow: none;
    text-align: center;
}

#consult_product #js-product-list .products .product .product-miniature .thumbnail-container .product-thumbnail {
    position: relative;
}

#consult_product #js-product-list .products .product .product-miniature .thumbnail-container .product-thumbnail img {
    object-fit: cover;
    position: relative;
}

#consult_product #js-product-list .products .product .product-miniature .thumbnail-container .product-thumbnail a.cercle {
    position: absolute;
    top: 50%;
    left: 50%;
    background: var(--secondary);
    font-family: "Inter", sans-serif;
    font-weight: 500;
    transform: translate(-50%, -50%);
    color: var(--font_secondary);
    text-decoration: none;
    font-size: 1rem;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    visibility: hidden;
    opacity: 0;
    transition: visibility .5s, opacity .5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

@media (max-width: 1520px) {
    #consult_product #js-product-list .products .product .product-miniature .thumbnail-container .product-thumbnail a.cercle {
        width: 200px;
        height: 200px;
    }
}

@media (max-width: 1280px) {
    #consult_product #js-product-list .products .product .product-miniature .thumbnail-container .product-thumbnail a.cercle {
        width: 180px;
        height: 180px;
    }
}

@media (max-width: 1199px) {
    #consult_product #js-product-list .products .product .product-miniature .thumbnail-container .product-thumbnail a.cercle {
        width: 200px;
        height: 200px;
    }
}

@media (max-width: 991px) {
    #consult_product #js-product-list .products .product .product-miniature .thumbnail-container .product-thumbnail a.cercle {
        width: 250px;
        height: 250px;
    }
}

#consult_product #js-product-list .products .product .product-miniature .thumbnail-container .product-thumbnail a.cercle:hover {
    text-decoration: underline;
}

#consult_product #js-product-list .products .product .product-miniature .thumbnail-container .product-thumbnail a.cercle svg {
    fill: var(--font_secondary);
    width: 13px;
    height: 7px;
    transform: rotate(-90deg) translateX(-9px);
    display: inline-block;
    margin: -8px 0 10px;
}

#consult_product #js-product-list .products .product .product-miniature .thumbnail-container .product-thumbnail:hover a.cercle {
    visibility: visible;
    opacity: 1;
}

#consult_product #js-product-list .products .product .product-miniature .thumbnail-container .product-description {
    position: relative;
    text-align: center;
    z-index: unset;
    background: transparent;
    width: unset;
    height: unset;
    bottom: unset;
    box-shadow: none;
}

#consult_product #js-product-list .products .product .product-miniature .thumbnail-container .product-description::after {
    content: none;
}

#consult_product #js-product-list .products .product .product-miniature .thumbnail-container .product-description .product-title {
    font-weight: 600;
    margin-top: 0;
}

#consult_product #js-product-list .products .product .product-miniature .thumbnail-container .product-description .product-title a {
    color: var(--font);
    font-size: 1rem;
    text-decoration: none;
}

#consult_product #js-product-list .products .product .product-miniature .thumbnail-container .product-description .price {
    font-weight: 500;
    color: var(--ternary);
    font-size: 1.125rem;
    letter-spacing: -0.36px;
}

#consult_product #js-product-list .products .product .product-miniature .thumbnail-container .product-flags li.product-flag.discount {
    background: var(--primary);
    color: var(--font);
}

#consult_product #js-product-list .pagination {
    color: var(--font);
}

@media (max-width: 767px) {
    #consult_product #js-product-list .pagination {
        justify-content: space-between;
    }
}

@media (max-width: 400px) {
    #consult_product #js-product-list .pagination {
        flex-direction: column-reverse;
    }
}

@media (max-width: 991px) {
    #consult_product #js-product-list .pagination div {
        padding: 0;
    }
}

#consult_product #js-product-list .pagination div .page-list {
    background: var(--background);
    box-shadow: none;
}

#consult_product #js-product-list .pagination div .page-list li {
    color: var(--font);
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
}

#consult_product #js-product-list .pagination div .page-list li a {
    color: var(--font);
    text-decoration: none;
}

#consult_product #js-product-list .pagination div .page-list li a:hover {
    text-decoration: underline;
}

#consult_product #js-product-list .pagination div .page-list li.current a {
    color: var(--ternary);
    font-size: 1rem;
}

#consult_product #js-product-list .up a {
    background: var(--ternary);
    color: var(--font_secondary);
    border-radius: 50px;
    border: 0;
    font-size: 1.0625rem;
    padding: 20px 30px;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    transition: all .3s ease;
}

#single_product {
    background: var(--background);
    color: var(--font);
    padding-top: 80px;
    padding-bottom: 80px;
}

#single_product .product-container #content .product-flags .discount {
    background: var(--primary);
    color: var(--font);
}

#single_product .product-container #content .product-flags .online-only {
    background: var(--secondary);
    color: var(--font_secondary);
}

#single_product .product-container #content .images-container .js-qv-mask .product-images li img.selected {
    border: 2px solid var(--ternary);
}

#single_product .product-container #content .images-container .js-qv-mask .product-images li img:not([class*="selected"]):hover {
    border: 2px solid var(--primary);
}

#single_product .product-container #content .scroll-box-arrows i {
    bottom: 0;
    color: var(--font);
}

#single_product .product-container #part_right h1 {
    font-size: 3.438rem;
    letter-spacing: -1.1px;
    font-family: "Marcellus", serif;
    font-weight: 400;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding-bottom: 13px;
    margin-bottom: 40px;
}

#single_product .product-container #part_right .product-prices .product-discount .regular-price {
    color: var(--information);
}

#single_product .product-container #part_right .product-prices .current-price span[itemprop="price"] {
    color: var(--ternary);
}

#single_product .product-container #part_right .product-prices .discount {
    background: var(--primary);
    color: var(--font);
}

#single_product .product-container #part_right .product-prices .tax-shipping-delivery-label {
    display: none;
}

#single_product .product-container #part_right .product-information {
    color: var(--font);
}

#single_product .product-container #part_right .product-information .product-description p span {
    font-size: 1rem !important;
    font-family: "Inter", sans-serif;
    font-weight: 500;
}

#single_product .product-container #part_right .product-information .product-actions form {
    font-size: 1rem;
}

#single_product .product-container #part_right .product-information .product-actions form .product-add-to-cart .product-quantity {
    flex-wrap: wrap;
}

#single_product .product-container #part_right .product-information .product-actions form .product-add-to-cart .product-quantity .qty {
    margin-right: 2rem;
}

#single_product .product-container #part_right .product-information .product-actions form .product-add-to-cart .product-quantity .qty .bootstrap-touchspin {
    flex-wrap: unset;
}

#single_product .product-container #part_right .product-information .product-actions form .product-add-to-cart .product-quantity .add button {
    background: var(--ternary);
    color: var(--font_secondary);
    display: flex;
    align-items: center;
    border-radius: 50px;
    border: 0;
    font-size: 1.0625rem;
    padding: 20px 30px;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    transition: all .3s ease;
}

#single_product .product-container #part_right .product-information .product-actions form .product-add-to-cart .product-quantity .add button:hover {
    background: var(--secondary);
}

#single_product .product-container #part_right .product-information .product-actions form .product-add-to-cart .product-quantity .add button .material-icons {
    line-height: normal;
}

#single_product .product-container #part_right .product-information .blockreassurance_product {
    margin-top: 25px;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    padding-top: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

#single_product .product-container #part_right .product-information .blockreassurance_product div:not([class="block-title"]) {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

#single_product .product-container #part_right .product-information .blockreassurance_product div:not([class="block-title"]) svg {
    height: 65px;
    width: 65px;
    fill: var(--ternary);
}

#single_product .product-container #part_right .product-information .blockreassurance_product div:not([class="block-title"]) .block-title {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 1rem;
}

#single_product .product-container #part_right .product-information .tabs {
    box-shadow: none;
    font-family: "Inter", sans-serif;
    padding: 0;
}

#single_product .product-container #part_right .product-information .tabs .nav-tabs .nav-item {
    text-transform: uppercase;
}

#single_product .product-container #part_right .product-information .tabs .nav-tabs li a {
    color: var(--font);
}

#single_product .product-container #part_right .product-information .tabs .nav-tabs li a.active {
    color: var(--ternary);
    border-bottom: 2px solid var(--ternary);
}

#single_product .product-container #part_right .product-information .tabs .nav-tabs li a:hover {
    border-bottom: 2px solid var(--ternary);
}

#single_product .product-container #part_right .product-information .tabs #tab-content {
    margin-top: 1.5rem;
}

#single_product .product-container #part_right .product-information .tabs #tab-content .tab-pane label, #single_product .product-container #part_right .product-information .tabs #tab-content .tab-pane span, #single_product .product-container #part_right .product-information .tabs #tab-content .tab-pane p, #single_product .product-container #part_right .product-information .tabs #tab-content .tab-pane dl {
    color: var(--font);
}

#single_product .product-container #part_right .product-information .tabs #tab-content .tab-pane.fade.in {
    opacity: 1;
    padding: 0 1em;
}

#single_product .product-container #part_right .product-information .tabs #tab-content .tab-pane dt:nth-of-type(even), #single_product .product-container #part_right .product-information .tabs #tab-content .tab-pane dd:nth-of-type(even) {
    background: var(--primary);
}

#single_product .product-container #part_right .product-information .tabs #tab-content .tab-pane dt:nth-of-type(odd), #single_product .product-container #part_right .product-information .tabs #tab-content .tab-pane dd:nth-of-type(odd) {
    background: var(--hover);
}

#single_product .modal.fade.in {
    opacity: 1;
    background: rgba(0, 0, 0, 0.7);
}

#single_product .modal.fade.in .modal-content .modal-body .image-caption {
    border-top: none;
}

#single_product .modal.fade.in .modal-content .modal-body .image-caption span {
    color: var(--font);
    font-size: 1rem !important;
    font-family: "Inter", sans-serif;
    font-weight: 500;
}

#single_product .modal.fade.in .modal-content .modal-body aside .product-images li img.selected, #single_product .modal.fade.in .modal-content .modal-body aside .product-images li img:hover {
    border: 3px solid var(--ternary);
}

#blockcart-modal.show {
    opacity: 1;
}

@media (max-width: 450px) {
    #blockcart-modal.show .modal-content .modal-header {
        flex-direction: column;
        align-items: flex-end;
    }
}

#blockcart-modal.show .modal-content .modal-header button,
  #blockcart-modal.show .modal-content .modal-header .modal-title {
    color: var(--font);
}

#blockcart-modal.show .modal-content .modal-body .divide-right h6 {
    color: var(--ternary);
    font-family: "Marcellus", serif;
    font-weight: 400;
}

#blockcart-modal.show .modal-content .modal-body .divide-right span {
    color: var(--font);
}

@media (max-width: 500px) {
    #blockcart-modal.show .modal-content .modal-body .cart-content {
        text-align: center;
    }
}

#blockcart-modal.show .modal-content .modal-body .cart-content .cart-products-count {
    color: var(--font);
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

#blockcart-modal.show .modal-content .modal-body .cart-content .product-total {
    background: var(--primary);
}

@media (max-width: 500px) {
    #blockcart-modal.show .modal-content .modal-body .cart-content .cart-content-btn {
        flex-direction: column;
    }
}

#blockcart-modal.show .modal-content .modal-body .cart-content .cart-content-btn button {
    background: none;
    border: none;
    color: var(--font);
    box-shadow: none;
}

#blockcart-modal.show .modal-content .modal-body .cart-content .cart-content-btn button:hover #blockcart-modal.show .modal-content .modal-body .cart-content .cart-content-btn button.focus {
    background: none;
    border: none;
    color: var(--ternary);
    box-shadow: none;
}

#blockcart-modal.show .modal-content .modal-body .cart-content .cart-content-btn a {
    background: var(--ternary);
    color: var(--font_secondary);
    border-radius: 50px;
    border: 0;
    font-size: 1.0625rem;
    padding: 20px 30px;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    transition: all .3s ease;
}

#blockcart-modal.show .modal-content .modal-body .cart-content .cart-content-btn a:hover {
    background: var(--secondary);
}

#error-404 {
    color: var(--font) !important;
    background: var(--background) !important;
    box-shadow: none !important;
    margin-bottom: 1rem !important;
}

.contact-form {
    background: var(--background);
}

.contact-form h1,
  .contact-form label,
  .contact-form input,
  .contact-form select {
    color: var(--font);
}

.contact-form h1 {
    font-size: 3.438rem;
    letter-spacing: -1.1px;
    font-family: "Marcellus", serif;
    font-weight: 400;
}

@media (max-width: 450px) {
    .contact-form h1 {
        font-size: 2.438rem;
    }
}

.contact-form form .custom-radio input[type=radio]:checked + span {
    background-color: var(--primary);
}

.contact-form form .form-group label {
    color: var(--font);
}

.contact-form form .form-group .custom-checkbox span {
    margin-top: 0;
}

.contact-form form .form-group .form-control.focus,
  .contact-form form .form-group .input-group.focus {
    outline: none;
}

.contact-form form .form-group .form-control:focus,
  .contact-form form .form-group .input-group:focus {
    box-shadow: none;
    border-color: var(--primary);
}

@media (max-width: 720px) {
    .contact-form form .form-group .input-group-btn {
        display: none;
    }
}

.contact-form form .form-group .input-group-btn button,
  .contact-form form .form-group .input-group-btn label {
    background: var(--ternary);
    color: var(--font_secondary);
    height: 38px;
}

.contact-form form .form-group .input-group-btn button:hover,
    .contact-form form .form-group .input-group-btn label:hover {
    background: var(--secondary);
}

.contact-form form .form-group .form-control-comment {
    color: var(--information);
}

.contact-form form input[type="submit"] {
    background: var(--ternary);
    color: var(--font_secondary);
    border-radius: 50px;
    border: 0;
    font-size: 1.0625rem;
    padding: 20px 30px;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    transition: all .3s ease;
}

.contact-form form input[type="submit"]:hover {
    background: var(--secondary);
}

@media (max-width: 768px) {
    #search_filters_wrapper {
        margin: 0 !important;
    }
}

#search_filters_wrapper #search_filters {
    box-shadow: unset;
    background: var(--hover);
    padding-left: 4.25rem;
    padding-right: 4.25rem;
}

@media (max-width: 1450px) {
    #search_filters_wrapper #search_filters {
        padding-left: 2.75rem;
        padding-right: 2.75rem;
    }
}

@media (max-width: 768px) {
    #search_filters_wrapper #search_filters {
        padding-top: 1.5rem !important;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    #search_filters_wrapper #search_filters .h6 {
        padding-left: .625rem;
    }
}

#search_filters_wrapper #search_filters .js-search-filters-clear-all {
    background: var(--ternary);
    color: var(--font_secondary);
    border-radius: 0;
    margin-bottom: 2rem;
}

#search_filters_wrapper #search_filters section .facet-title {
    color: var(--ternary) !important;
}

@media (max-width: 1275px) {
    #search_filters_wrapper #search_filters section ul {
        padding-left: 0;
    }
}

#search_filters_wrapper #search_filters section a {
    text-decoration: none;
    width: fit-content;
}

#search_filters_wrapper #search_filters section .ui-slider-horizontal {
    border: none;
    background: var(--primary);
}

#search_filters_wrapper #search_filters section .ui-slider-horizontal .ui-slider-range {
    background: var(--ternary);
}

#search_filters_wrapper #search_filters section .ui-slider-horizontal .ui-slider-handle {
    border-width: 3px;
    border-color: var(--ternary);
}

#search_filters_wrapper #search_filters section .ui-slider-horizontal .ui-slider-handle:focus {
    background: var(--ternary);
    outline: unset;
}

#js-active-search-filters {
    background: var(--primary);
    color: var(--font);
}

#js-active-search-filters .active-filter-title {
    font-weight: 400;
}

@media (max-width: 400px) {
    #js-active-search-filters ul {
        padding-left: 0;
        margin-top: 1rem;
        display: block;
    }
}

#js-active-search-filters ul .filter-block {
    box-shadow: unset;
    background: var(--hover);
    color: var(--ternary);
}

#js-active-search-filters ul .filter-block .material-icons {
    color: var(--ternary);
}

.login-form form label {
    color: var(--font);
}

.login-form form .form-group .form-control.focus,
.login-form form .form-group .input-group.focus {
    outline: none;
}

.login-form form .form-group .form-control:focus,
.login-form form .form-group .input-group:focus {
    box-shadow: none;
    border-color: var(--primary);
}

@media (max-width: 650px) {
    .login-form form .form-group .input-group-btn {
        display: none;
    }
}

.login-form form .form-group .input-group-btn button {
    background: var(--ternary) !important;
    color: var(--font_secondary) !important;
}

.login-form form .form-group .input-group-btn button:hover {
    background: var(--secondary) !important;
}

.login-form form .forgot-password a {
    text-decoration: none;
    color: var(--font);
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

.login-form form .forgot-password a:hover {
    text-decoration: underline;
    color: var(--font);
}

.login-form form .form-footer button {
    background: var(--ternary);
    color: var(--font_secondary);
    border-radius: 50px;
    border: 0;
    font-size: 1.0625rem;
    padding: 20px 30px;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    transition: all .3s ease;
}

.login-form form .form-footer button:hover {
    background: var(--secondary);
}

.no-account a {
    text-decoration: none;
    color: var(--font);
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

.no-account a:hover {
    text-decoration: underline;
    color: var(--font);
}

.register-form p a {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    color: var(--font);
    text-decoration: none;
}

.register-form p a:hover {
    text-decoration: underline;
}

.register-form #customer-form .custom-radio input[type=radio]:checked + span {
    background-color: var(--primary);
}

.register-form #customer-form .form-group label {
    color: var(--font);
}

.register-form #customer-form .form-group .custom-checkbox span {
    margin-top: 0;
}

.register-form #customer-form .form-group .form-control.focus,
.register-form #customer-form .form-group .input-group.focus {
    outline: none;
}

.register-form #customer-form .form-group .form-control:focus,
.register-form #customer-form .form-group .input-group:focus {
    box-shadow: none;
    border-color: var(--primary);
}

@media (max-width: 600px) {
    .register-form #customer-form .form-group .input-group-btn {
        display: none;
    }
}

.register-form #customer-form .form-group .input-group-btn button {
    background: var(--ternary);
    color: var(--font_secondary);
}

.register-form #customer-form .form-group .input-group-btn button:hover {
    background: var(--secondary);
}

.register-form #customer-form .form-group .form-control-comment {
    color: var(--information);
}

.register-form #customer-form .js-terms a {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    color: var(--font);
}

.register-form #customer-form .js-terms a:hover {
    text-decoration: underline;
    color: var(--ternary);
}

.register-form #customer-form .form-control-submit {
    background: var(--ternary);
    color: var(--font_secondary);
    border-radius: 50px;
    border: 0;
    font-size: 1.0625rem;
    padding: 20px 30px;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    transition: all .3s ease;
}

.register-form #customer-form .form-control-submit:hover {
    background: var(--secondary);
}

.forgotten-password .form-fields .row {
    align-items: center;
}

.forgotten-password .form-fields .row label {
    padding-top: 0;
}

.forgotten-password .form-fields .row button {
    height: unset;
    background: var(--ternary);
    color: var(--font_secondary);
    border-radius: 50px;
    border: 0;
    font-size: 1.0625rem;
    padding: 10px 20px;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    transition: all .3s ease;
}

.forgotten-password .form-fields .row button:hover {
    background: var(--secondary);
}

#checkout {
    background: var(--background);
    color: var(--font);
}

#checkout .cart-grid .chapeau {
    background: var(--hover);
    padding: 5.5rem;
    margin: 0 0 56px;
    border: 0;
}

@media (max-width: 450px) {
    #checkout .cart-grid .chapeau {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

#checkout .cart-grid .chapeau h1 {
    font-size: 3.438rem;
    letter-spacing: -1.1px;
    font-family: "Marcellus", serif;
    font-weight: 400;
}

@media (max-width: 450px) {
    #checkout .cart-grid .chapeau h1 {
        font-size: 2.438rem;
    }
}

#checkout .cart-grid .cart-grid-body .card {
    box-shadow: none;
    border: none;
}

@media (max-width: 768px) {
    #checkout .cart-grid .cart-grid-body .card .cart-items .cart-item {
        border-bottom: solid 1px var(--information);
    }
}

#checkout .cart-grid .cart-grid-body .card .cart-items .cart-item .product-line-grid .product-line-grid-body .product-line-info a:hover {
    color: var(--ternary);
}

#checkout .cart-grid .cart-grid-body .card .cart-items .cart-item .product-line-grid .product-line-grid-body .regular-price {
    color: var(--information);
}

#checkout .cart-grid .cart-grid-body .card .cart-items .cart-item .product-line-grid .product-line-grid-body .discount {
    background: var(--primary);
    color: var(--font);
}

#checkout .cart-grid .cart-grid-body .card .cart-items .cart-item .product-line-grid .product-line-grid-body .price {
    color: var(--ternary);
}

@media (max-width: 768px) {
    #checkout .cart-grid .cart-grid-body .card .cart-items .cart-item .product-line-grid .product-line-actions .row .info_price {
        width: 75%;
    }

    #checkout .cart-grid .cart-grid-body .card .cart-items .cart-item .product-line-grid .product-line-actions .row .info_delete {
        width: 25%;
    }
}

#checkout .cart-grid .cart-grid-body .card .cart-items .cart-item .product-line-grid .product-line-actions .row .cart-line-product-actions .remove-from-cart .material-icons:hover {
    color: var(--ternary);
}

#checkout .cart-grid .cart-grid-body #go_back_purchase:hover {
    color: var(--ternary);
}

#checkout .cart-grid .cart-grid-right {
    border-left: solid 1px var(--primary);
}

#checkout .cart-grid .cart-grid-right .card {
    box-shadow: none;
    border: none;
}

#checkout .cart-grid .cart-grid-right .card .cart-detailed-totals .cart-summary-totals .cart-summary-line {
    background: var(--primary);
}

#checkout .cart-grid .cart-grid-right .card .cart-detailed-totals .block-promo a {
    color: var(--font);
    font-family: "Inter", sans-serif;
    font-weight: 600;
    text-decoration: underline;
}

#checkout .cart-grid .cart-grid-right .card .cart-detailed-totals .block-promo a:hover {
    text-decoration: underline;
}

#checkout .cart-grid .cart-grid-right .card .cart-detailed-totals .block-promo form {
    background: var(--background);
    color: var(--font);
}

#checkout .cart-grid .cart-grid-right .card .cart-detailed-totals .block-promo form input.focus {
    outline: none;
}

#checkout .cart-grid .cart-grid-right .card .cart-detailed-totals .block-promo form input:focus {
    box-shadow: none;
    border-color: var(--primary);
}

#checkout .cart-grid .cart-grid-right .card .cart-detailed-totals .block-promo form button {
    background: var(--ternary);
    color: var(--font_secondary);
    height: 2.5rem;
    border: 0;
    font-size: 1.0625rem;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    transition: all .3s ease;
}

#checkout .cart-grid .cart-grid-right .card .cart-detailed-totals .block-promo form button:hover {
    background: var(--secondary);
}

#checkout .cart-grid .cart-grid-right .card .checkout {
    text-align: center;
}

#checkout .cart-grid .cart-grid-right .card .checkout a {
    background: var(--ternary);
    color: var(--font_secondary);
    border-radius: 50px;
    border: 0;
    font-size: 1.0625rem;
    padding: 20px 50px;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    transition: all .3s ease;
}

#checkout .cart-grid .cart-grid-right .card .checkout a:hover {
    background: var(--secondary);
}

#checkout .cart-grid .cart-grid-right .blockreassurance_product div:not([class="block-title"]) {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

#checkout .cart-grid .cart-grid-right .blockreassurance_product div:not([class="block-title"]) svg {
    height: 65px;
    width: 65px;
    fill: var(--ternary);
}

#checkout .cart-grid .cart-grid-right .blockreassurance_product div:not([class="block-title"]) .block-title {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 1rem;
}

body#checkout #header #_desktop_cart .blockcart.active {
    background: var(--ternary);
}

body#checkout #checkout_info {
    color: var(--font);
    background: var(--background);
    margin-bottom: 0 !important;
    padding: 0;
    box-shadow: unset;
    border: none;
    min-height: calc(100vh - 100px - 72px);
}

body#checkout #checkout_info .cart-grid-body .checkout-step {
    box-shadow: none;
    border-color: var(--information);
}

body#checkout #checkout_info .cart-grid-body .checkout-step h1 {
    font-size: 3.438rem;
    letter-spacing: -1.1px;
    font-family: "Marcellus", serif;
    font-weight: 400;
    color: var(--ternary);
}

@media (max-width: 400px) {
    body#checkout #checkout_info .cart-grid-body .checkout-step h1 {
        font-size: 3rem;
    }
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content p > a {
    text-decoration: none;
    color: var(--font);
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content p > a:hover {
    text-decoration: underline;
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content p > small {
    color: var(--information);
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content .nav {
    align-items: center;
}

@media (max-width: 600px) {
    body#checkout #checkout_info .cart-grid-body .checkout-step .content .nav {
        flex-direction: column;
    }
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content .nav .nav-item .nav-link {
    color: var(--font);
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content .nav .nav-item .nav-link.active {
    color: var(--ternary);
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content .nav .nav-item:nth-child(2) {
    font-size: 2rem;
}

@media (max-width: 600px) {
    body#checkout #checkout_info .cart-grid-body .checkout-step .content .nav .nav-item:nth-child(2) {
        display: none;
    }
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form h2 {
    color: var(--font);
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form #delivery-addresses .address-item {
    background: var(--background);
    border: none;
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form #delivery-addresses .address-item.selected {
    border: solid 2px var(--primary);
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form #delivery-addresses hr {
    display: none;
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form .custom-radio input[type=radio]:checked + span {
    background-color: var(--primary);
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form .form-group label {
    color: var(--font);
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form .form-group .custom-checkbox span {
    margin-top: 0;
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form .form-group .form-control.focus,
    body#checkout #checkout_info .cart-grid-body .checkout-step .content form .form-group .input-group.focus {
    outline: none;
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form .form-group .form-control:focus,
    body#checkout #checkout_info .cart-grid-body .checkout-step .content form .form-group .input-group:focus {
    box-shadow: none;
    border-color: var(--primary);
}

@media (max-width: 600px) {
    body#checkout #checkout_info .cart-grid-body .checkout-step .content form .form-group .input-group-btn {
        display: none;
    }
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form .form-group .input-group-btn button {
    background: var(--ternary);
    color: var(--font_secondary);
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form .form-group .input-group-btn button:hover {
    background: var(--secondary);
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form .form-group .form-control-comment {
    color: var(--information);
}

@media (max-width: 768px) {
    body#checkout #checkout_info .cart-grid-body .checkout-step .content form .forgot-password {
        padding-left: 0;
    }
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form .forgot-password a {
    text-decoration: none;
    color: var(--font);
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form .forgot-password a:hover {
    text-decoration: underline;
    color: var(--font);
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form .delivery-options .delivery-option {
    background: var(--hover);
    color: var(--font);
    border-right: none;
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form .delivery-options .delivery-option .custom-radio input[type=radio]:checked + span {
    background-color: var(--font);
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form .js-terms a {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    color: var(--font);
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form .js-terms a:hover {
    text-decoration: underline;
    color: var(--ternary);
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form .continue {
    background: var(--ternary);
    color: var(--font_secondary);
    border-radius: 50px;
    border: 0;
    font-size: 1.0625rem;
    padding: 20px 30px;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    transition: all .3s ease;
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form .continue:hover {
    background: var(--secondary);
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form .cancel-address {
    color: var(--font);
    padding: 12px 10px;
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form#conditions-to-approve {
    padding-bottom: 1rem;
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content form#conditions-to-approve ul {
    padding-left: 0;
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content #payment-confirmation button {
    background: var(--ternary);
    color: var(--font_secondary);
    border-radius: 50px;
    border: 0;
    font-size: 1.0625rem;
    padding: 20px 30px;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    transition: all .3s ease;
}

body#checkout #checkout_info .cart-grid-body .checkout-step .content #payment-confirmation button:hover {
    background: var(--secondary);
}

body#checkout #checkout_info .cart-grid-body .checkout-step .text-muted {
    color: var(--information) !important;
}

body#checkout #checkout_info .cart-grid-right .cart-summary-products a {
    color: var(--font);
}

body#checkout #checkout_info .cart-grid-right .cart-summary-products a:hover {
    text-decoration: underline;
}

body#checkout #checkout_info .cart-grid-right .cart-summary-products .collapse.in {
    display: block;
}

body#checkout #checkout_info .cart-grid-right .cart-summary-products .collapse.in .product-price {
    color: var(--ternary);
}

body#checkout #checkout_info .cart-grid-right .cart-summary-products .collapse.in .text-muted {
    color: var(--information);
}

body#checkout #checkout_info .cart-grid-right .cart-summary-totals .cart-summary-line {
    background: var(--primary);
}

body#checkout #checkout_info .cart-grid-right .blockreassurance_product div:not([class="block-title"]) {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

body#checkout #checkout_info .cart-grid-right .blockreassurance_product div:not([class="block-title"]) svg {
    height: 65px;
    width: 65px;
    fill: var(--ternary);
}

body#checkout #checkout_info .cart-grid-right .blockreassurance_product div:not([class="block-title"]) .block-title {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 1rem;
}

body#checkout #footer {
    background: var(--secondary);
    color: var(--font_header);
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    padding: 1.5rem 0;
}

body#order-confirmation #wrapper #main #content-hook_order_confirmation {
    color: var(--font);
    background: var(--background);
    border: none;
    box-shadow: none;
}

body#order-confirmation #wrapper #main #content-hook_order_confirmation .chapeau {
    background: var(--hover);
    padding: 5.5rem;
    margin: 0 0 56px;
    border: 0;
}

@media (max-width: 768px) {
    body#order-confirmation #wrapper #main #content-hook_order_confirmation .chapeau {
        padding: 4rem;
    }
}

@media (max-width: 450px) {
    body#order-confirmation #wrapper #main #content-hook_order_confirmation .chapeau {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

body#order-confirmation #wrapper #main #content-hook_order_confirmation .chapeau h1 {
    font-size: 3.438rem;
    letter-spacing: -1.1px;
    font-family: "Marcellus", serif;
    font-weight: 400;
}

@media (max-width: 768px) {
    body#order-confirmation #wrapper #main #content-hook_order_confirmation .chapeau h1 {
        font-size: 3rem;
    }
}

@media (max-width: 450px) {
    body#order-confirmation #wrapper #main #content-hook_order_confirmation .chapeau h1 {
        font-size: 2.438rem;
    }
}

body#order-confirmation #wrapper #main #content-hook_order_confirmation .chapeau p a {
    color: var(--ternary);
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

body#order-confirmation #wrapper #main .page-order-confirmation .card-block #order-items .order-confirmation-table hr {
    color: var(--primary);
    border-top: none;
}

body#order-confirmation #wrapper #main .page-order-confirmation .card-block #order-items .order-confirmation-table table tbody .total-value {
    background: var(--primary);
}

body#my-account #main #content .row {
    margin: 0;
}

body#my-account #main #content .row .links {
    background: var(--background);
}

body#my-account #main #content .row .links a {
    color: var(--font);
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

body#my-account #main #content .row .links a:hover {
    background: var(--hover);
}

body#my-account #main #content .row .links a .link-item {
    box-shadow: none;
    background: unset;
}

body#my-account #main #content .row .links a .link-item i {
    color: var(--primary);
}

body.page-customer-account #content, body.page-addresses #content {
    box-shadow: none;
}

body.page-customer-account #content form, body.page-addresses #content form {
    background: var(--background);
    color: var(--font);
}

body.page-customer-account #content form .custom-radio input[type=radio]:checked + span, body.page-addresses #content form .custom-radio input[type=radio]:checked + span {
    background-color: var(--primary);
}

body.page-customer-account #content form .form-group label, body.page-addresses #content form .form-group label {
    color: var(--font);
}

body.page-customer-account #content form .form-group .custom-checkbox span, body.page-addresses #content form .form-group .custom-checkbox span {
    margin-top: 0;
}

body.page-customer-account #content form .form-group .form-control.focus,
    body.page-customer-account #content form .form-group .input-group.focus, body.page-addresses #content form .form-group .form-control.focus,
    body.page-addresses #content form .form-group .input-group.focus {
    outline: none;
}

body.page-customer-account #content form .form-group .form-control:focus,
    body.page-customer-account #content form .form-group .input-group:focus, body.page-addresses #content form .form-group .form-control:focus,
    body.page-addresses #content form .form-group .input-group:focus {
    box-shadow: none;
    border-color: var(--primary);
}

@media (max-width: 768px) {
    body.page-customer-account #content form .form-group .input-group-btn, body.page-addresses #content form .form-group .input-group-btn {
        display: none;
    }
}

body.page-customer-account #content form .form-group .input-group-btn button, body.page-addresses #content form .form-group .input-group-btn button {
    background: var(--ternary);
    color: var(--font_secondary);
}

body.page-customer-account #content form .form-group .input-group-btn button:hover, body.page-addresses #content form .form-group .input-group-btn button:hover {
    background: var(--secondary);
}

body.page-customer-account #content form .form-group .form-control-comment, body.page-addresses #content form .form-group .form-control-comment {
    color: var(--information);
}

body.page-customer-account #content form .js-terms a, body.page-addresses #content form .js-terms a {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    color: var(--font);
}

body.page-customer-account #content form .js-terms a:hover, body.page-addresses #content form .js-terms a:hover {
    text-decoration: underline;
    color: var(--ternary);
}

body.page-customer-account #content form .form-control-submit, body.page-addresses #content form .form-control-submit {
    background: var(--ternary);
    color: var(--font_secondary);
    border-radius: 50px;
    border: 0;
    font-size: 1.0625rem;
    padding: 20px 30px;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    transition: all .3s ease;
}

body.page-customer-account #content form .form-control-submit:hover, body.page-addresses #content form .form-control-submit:hover {
    background: var(--secondary);
}

body.page-customer-account #content .address, body.page-addresses #content .address {
    box-shadow: none;
    background: var(--background);
    color: var(--font);
}

body.page-customer-account #content .address:hover, body.page-addresses #content .address:hover {
    background: var(--hover);
}

body.page-customer-account #content .address .address-body h4, body.page-addresses #content .address .address-body h4 {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 1.75rem;
    color: var(--ternary);
}

body.page-customer-account #content .address .address-body address, body.page-addresses #content .address .address-body address {
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    min-height: unset;
}

body.page-customer-account #content .address .address-footer, body.page-addresses #content .address .address-footer {
    border-color: var(--font);
}

body.page-customer-account #content .address .address-footer a, body.page-addresses #content .address .address-footer a {
    color: var(--information);
    text-decoration: none;
}

body.page-customer-account #content .address .address-footer a:hover, body.page-addresses #content .address .address-footer a:hover {
    text-decoration: underline;
}

body.page-customer-account #content .addresses-footer, body.page-addresses #content .addresses-footer {
    margin-bottom: 2rem;
}

body.page-customer-account #content .addresses-footer a, body.page-addresses #content .addresses-footer a {
    color: var(--font);
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

body.page-customer-account #content .addresses-footer a:hover, body.page-addresses #content .addresses-footer a:hover {
    text-decoration: underline;
}

body.page-customer-account #content h6, body.page-addresses #content h6 {
    color: var(--font);
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

body.page-customer-account #content table, body.page-addresses #content table {
    color: var(--font);
    border-color: var(--secondary);
}

body.page-customer-account #content table thead th, body.page-addresses #content table thead th {
    color: var(--font);
}

body.page-customer-account #content table tbody tr:nth-of-type(odd), body.page-addresses #content table tbody tr:nth-of-type(odd) {
    background: var(--hover);
}

body.page-customer-account #content table tbody tr:nth-of-type(even), body.page-addresses #content table tbody tr:nth-of-type(even) {
    background: var(--primary);
}

body.page-customer-account #content table tbody tr:hover, body.page-addresses #content table tbody tr:hover {
    opacity: .8;
}

body.page-customer-account #content table tbody tr td a, body.page-addresses #content table tbody tr td a {
    color: var(--font);
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

body.page-customer-account #content table tbody tr td a:hover, body.page-addresses #content table tbody tr td a:hover {
    text-decoration: underline;
}

body.page-customer-account #content table.table-bordered td, body.page-customer-account #content table.table-bordered th, body.page-addresses #content table.table-bordered td, body.page-addresses #content table.table-bordered th {
    border: none !important;
}

body.page-customer-account #content .psgdprinfo17, body.page-addresses #content .psgdprinfo17 {
    float: none;
    background: var(--background);
    color: var(--font);
}

body.page-customer-account #content .psgdprinfo17:nth-child(2), body.page-addresses #content .psgdprinfo17:nth-child(2) {
    margin-top: 3rem;
}

@media (max-width: 768px) {
    body.page-customer-account #content .psgdprinfo17:nth-child(2), body.page-addresses #content .psgdprinfo17:nth-child(2) {
        margin-top: 0;
    }
}

body.page-customer-account #content .psgdprinfo17 h2, body.page-addresses #content .psgdprinfo17 h2 {
    color: var(--ternary);
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

body.page-customer-account #content .psgdprinfo17 a, body.page-addresses #content .psgdprinfo17 a {
    color: var(--font);
    font-family: "Inter", sans-serif;
    font-weight: 600;
    text-decoration: underline;
}

body.page-customer-account #content .psgdprinfo17 a.btn, body.page-addresses #content .psgdprinfo17 a.btn {
    background: var(--ternary);
    color: var(--font_secondary);
    border-radius: 50px;
    border: 0;
    font-size: 1.0625rem;
    padding: 20px 30px;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    transition: all .3s ease;
}

@media (max-width: 768px) {
    body.page-customer-account #content .psgdprinfo17 a.btn, body.page-addresses #content .psgdprinfo17 a.btn {
        float: none;
        margin-bottom: 1rem;
    }
}

body.page-customer-account #content .psgdprinfo17 a.btn:hover, body.page-addresses #content .psgdprinfo17 a.btn:hover {
    background: var(--secondary);
}

body#order-detail #wrapper #main #content .box,
body#order-detail #wrapper #main #content #order-history,
body#order-detail #wrapper #main #content .addresses,
body#order-detail #wrapper #main #content #notifications {
    box-shadow: none;
    margin: 0;
}

body#order-detail #wrapper #main #content a {
    color: var(--font);
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

body#order-detail #wrapper #main #content a:hover {
    text-decoration: underline;
}

body#order-detail #wrapper #main #content ul {
    padding-left: 0;
}

body#order-detail #wrapper #main #content table {
    color: var(--font);
    border-color: var(--secondary);
}

body#order-detail #wrapper #main #content table thead th {
    color: var(--font);
}

body#order-detail #wrapper #main #content table tbody tr:nth-of-type(odd) {
    background: var(--hover);
}

body#order-detail #wrapper #main #content table tbody tr:nth-of-type(even) {
    background: var(--primary);
}

body#order-detail #wrapper #main #content table tbody tr:hover {
    opacity: .8;
}

body#order-detail #wrapper #main #content table tbody tr td a {
    color: var(--font);
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
}

body#order-detail #wrapper #main #content table tbody tr td a:hover {
    text-decoration: underline;
}

body#order-detail #wrapper #main #content table.table-bordered td, body#order-detail #wrapper #main #content table.table-bordered th {
    border: none !important;
}

body#order-detail #wrapper #main #content form {
    background: var(--background);
    color: var(--font);
}

body#order-detail #wrapper #main #content form .custom-radio input[type=radio]:checked + span {
    background-color: var(--primary);
}

body#order-detail #wrapper #main #content form .form-group label {
    color: var(--font);
}

body#order-detail #wrapper #main #content form .form-group .custom-checkbox span {
    margin-top: 0;
}

body#order-detail #wrapper #main #content form .form-group .form-control.focus,
  body#order-detail #wrapper #main #content form .form-group .input-group.focus {
    outline: none;
}

body#order-detail #wrapper #main #content form .form-group .form-control:focus,
  body#order-detail #wrapper #main #content form .form-group .input-group:focus {
    box-shadow: none;
    border-color: var(--primary);
}

body#order-detail #wrapper #main #content form .form-control-submit {
    background: var(--ternary);
    color: var(--font_secondary);
    border-radius: 50px;
    border: 0;
    font-size: 1.0625rem;
    padding: 20px 30px;
    text-decoration: none;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    transition: all .3s ease;
}

body#order-detail #wrapper #main #content form .form-control-submit:hover {
    background: var(--secondary);
}
