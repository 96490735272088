#temoignage{
	background: var(--background);
	color: var(--font_secondary);
	padding: 10.313rem 22.25rem;

	@media (max-width: 1600px) {
		padding: 10.313rem 16.25rem;
	}
	
	@media (max-width: 1300px) {
		padding: 10.313rem 10.25rem;
	}
	
	@media (max-width: 1000px) {
		padding: 6rem 6.25rem;
	}
	
	@media (max-width: 800px) {
		padding: 6rem 2.25rem;
	}
	
	@media (max-width: 650px) {
		padding: 6rem 1.25rem;
	}

	.temoignage_slider{
		.informations{
			background: var(--secondary);
			padding: 4.688rem 14.375rem 5.625rem;
			height: 430px;

			@media (max-width: 1645px) {
				padding: 4.688rem 6.375rem 5.625rem;
			}
			
			@media (max-width: 1000px) {
				padding: 4.688rem 2.375rem 5.625rem;
			}
			
			@media (max-width: 650px) {
				padding: 4.688rem 1.375rem 5.625rem;
			}
			
			@media (max-width: 500px) {
				height: 530px;
			}
	
			.temoignage_svg{
				svg{
					height: 33px;
					fill: var(--primary);
					margin-bottom: 1.363rem;
				}
			}
	
			h1{
				font-size: 3rem;
    			letter-spacing: -0.0125rem;
				margin: 0 0 1.263rem;
				font-family: $font_marcellus;
				font-weight: 400;

				@media (max-width: 400px) {
					font-size: 3rem;
				}
			}
	
			.temoignage_content{
				letter-spacing: -0.36px;
				font-size: 1.125rem;
				text-align: center;
				font-family: $font_inter;
				font-weight: 500;
			}
		}
	
		.temoignage_auteur{
			background: var(--primary);
			color: var(--secondary);
			text-align: center;
			padding: 1.25rem 0;
			font-family: $font_inter;
			font-weight: 600;
		}

		.slick-track {
			display: flex !important;
		}
	
		.slick-slide{
			height: inherit !important;
		}

		.slick-dots {
			display: none !important;

			@media (max-width: 800px) {
				display: block !important;
				bottom: -1.8rem;
	
				li {
					height: 10px;
					width: 10px;
		
					button {
						border: 2px solid var(--secondary);
						border-radius: 50%;
						height: 14px;
						width: 14px;
		
						&:focus {
							background: var(--primary);
						}
		
						&:before {
							color: transparent;
						}
		
						&[aria-selected]{
							border: 2px solid var(--primary);
							background: var(--primary);
						}
					}
				}
			}
		}
	
		button{
			margin: 0;
			padding: 0;
			background: none;
			border: none;
			border-radius: 0;
			outline: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;		

			&.slide-arrow{
				position: absolute;
				top: 50%;
				margin-top: -15px;

				@media (max-width: 800px) {
					display: none !important;
				}

				&.prev-arrow,
				&.next-arrow{
					border: 1px solid var(--information);
					border-radius: 50%;
					height: 65px;
					width: 65px;
					transition: ease all 300ms;

					svg{
						height: 20px;
						width: 20px;
					}

					&:hover{
						border: 1px solid var(--primary);
						background: var(--primary);
					}
				}

				&.prev-arrow{
					left: -100px;

					@media (max-width: 1000px) {
						left: -85px;
					}

					svg{
						transform: rotate(180deg);
					}
				}
				
				&.next-arrow{
					right: -100px;

					@media (max-width: 1000px) {
						right: -85px;
					}
				}
			}
		}
	}
	
	
}