#search_filters_wrapper{
	@media (max-width: 768px) {
		margin: 0 !important;
	}

	#search_filters{
		box-shadow: unset;
		background: var(--hover);
		padding-left: 4.25rem;
		padding-right: 4.25rem;

		@media (max-width: 1450px) {
			padding-left: 2.75rem;
			padding-right: 2.75rem;
		}
		
		@media (max-width: 768px) {
			padding-top: 1.5rem !important;
			padding-left: 2rem !important;
			padding-right: 2rem !important;

			.h6{
				padding-left: .625rem;
			}
		}

		.js-search-filters-clear-all{
			background: var(--ternary);
			color: var(--font_secondary);
			border-radius: 0;
			margin-bottom: 2rem;
		}

		section{
			.facet-title{
				color: var(--ternary) !important;
			}

			@media (max-width: 1275px) {
				ul{
					padding-left: 0;
				}
			}
	
			a{
				text-decoration: none;
				width: fit-content;
			}
	
			.ui-slider-horizontal{
				border: none;
				background: var(--primary);
	
				.ui-slider-range{
					background: var(--ternary);
				}
	
				.ui-slider-handle{
					border-width: 3px;
					border-color: var(--ternary);
	
					&:focus{
						background: var(--ternary);
						outline: unset;
					}
				}
			}
		}

	}
}

#js-active-search-filters{
	background: var(--primary);
	color: var(--font);

	.active-filter-title{
		font-weight: 400;
	}

	ul{
		@media (max-width: 400px) {
			padding-left: 0;
			margin-top: 1rem;
			display: block;
		}

		.filter-block{
			box-shadow: unset;
			background: var(--hover);
			color: var(--ternary);

			.material-icons{
				color: var(--ternary);
			}
		}
	}
}