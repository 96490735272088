.block-categories{
	background: var(--background);
	margin-top: 10rem;
	margin-bottom: 8rem;
	padding: 0;
	box-shadow: none;
	
	.categorie{
		position: relative;
		height: 465px;
		max-width: 490px;

		.categorie_title{
			position: absolute;
			z-index: 1;
			color: var(--font_secondary);
			font-family: $font_marcellus;
			font-weight: 400;
			font-size: 3rem;
			text-decoration: none;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			letter-spacing: -0.0125rem;
		}

		img{
			object-fit: cover;
			filter:brightness(35%);
			-webkit-filter: brightness(35%);
		}

		.categorie_content{
			background: var(--ternary);
			display: none;

			a{
				color: var(--font_secondary);
				font-family: $font_inter;
				font-weight: 600;
				font-size: 1rem;
				letter-spacing: -0.32px;
				text-decoration: none;
				text-transform: uppercase;

				&:hover{
					text-decoration: underline;
				}

				svg{
					fill: var(--font_secondary);
					width: 13px;
					height: 7px;
					transform: rotate(-90deg) translateX(-9px);
					display: inline-block;
					margin: -7px 0 10px;
				}
			}
		}

		&:hover{
			.categorie_title,
			img{
				display: none;
			}
			
			.categorie_content{
				display: flex;
			}
		}
	}
}