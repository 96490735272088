#carrousel{
	margin-bottom: 0;
	min-height: 700px;

	@media (max-width: 991px) {
		min-height: unset;
	}
	
	@media (max-width: 650px) {
		min-height: 300px;
		padding-bottom: 0;
	}

	.slide{
		.card{
			width: 50%;
			background: transparent;
			color: var(--font);
			border: none;
			z-index: 1;
			min-height: 733px;
			top: 50px;
			left: 330px;
			box-shadow: none;

			@media (max-width: 1375px) {
				width: 50%;
			}

			@media (max-width: 991px) {
				width: 100%;
				left: 0;
				min-height: unset;
			}
			
			@media (max-width: 650px) {
				position: absolute;
				top: 0;
				min-height: 300px;
			}

			.card-body{
				padding-top: 160px;
				padding-left: 160px;

				@media (max-width: 1600px) {
					padding-top: 4rem;
					padding-left: 8rem;
				}
				
				@media (max-width: 1375px) {
					padding-left: 6rem;
				}
				
				@media (max-width: 1050px) {
					padding-left: 2rem;
				}
				
				@media (max-width: 991px) {
					padding: 2rem 0 2rem 2rem;
				}

				@media (max-width: 650px) {
					padding: .5rem .5rem 1rem;
				}

				.card-sub_title{
					color: var(--primary);
					font-size: 2rem;
					font-family: $font_marcellus;
					font-weight: 400;
					position: relative;
					letter-spacing: .025rem;

					&::before {
						content: "";
						position: absolute;
						border-top: 1px solid var(--font_secondary);
						width: 5.938rem;
						margin: 0 1rem;
						transform: translateY(1.25rem) translateX(-8.25rem);
						opacity: .35; 
						
						@media (max-width: 1375px) {
							display: none;
						}
					}
				}
				
				.card-title{
					color: var(--font_secondary);
					font-size: 4.25rem;
					letter-spacing: -2.6px;
					width: 100%;
					margin-bottom: 3.5rem;
    				margin-top: 1.5rem;
					font-family: $font_inter;
					font-weight: 300;
					line-height: 4.25rem;

					@media (max-width: 1150px) {
						font-size: 3.5rem;
					}

					@media (max-width: 991px) {
						font-size: 3rem;
						letter-spacing: -2px;
						margin: 0.25rem 0 2rem;
					}

					@media (max-width: 650px) {
						font-size: 2rem;
					}
				}

				.card-link{
					margin-bottom: 3rem;

					@media (max-width: 650px) {
						text-align: center;
					}

					.link{
						background: var(--primary);
						color: var(--font);
						border-radius: 50px;
						border: 0;
						font-weight: 600;
						font-size: 1rem;
						padding: 20px 45px;
						text-decoration: none;
						font-family: $font_inter;
						font-weight: 600;
						text-transform: uppercase;
						transition: ease all 300ms;

						@media (max-width: 650px) {
							padding: 20px 30px;
							font-size: .75rem;
						}
	
						&:hover{
							background: var(--font_secondary);
						}
	
						svg{
							width: 20px;
							margin: -3px 4px 0 0;
						}
					}
				}
			}
		}

		.slide_image{
			height: 100%;
			height: 700px;
			background: $black;

			@media (max-width: 1375px) {
				width: 50%;
			}

			@media (max-width: 991px) {
				width: 100%;
				height: 100%;
				min-height: unset;
			}
			
			@media (max-width: 650px) {
				min-height: 300px;
				height: 100%;

				img:not(.filter_on){
					filter: opacity(50%);
				}
			}

			img{
				object-fit: cover;

				&.filter_on{
					filter: opacity(50%);
					-webkit-filter: opacity(50%);
				}
			}
		}
	}

	
	/* Surcharge du slick */
	.slick-track {
		display: flex !important;
	}

	.slick-slide{
		height: inherit !important;
	}

	button{
		margin: 0;
		padding: 0;
		background: none;
		border: none;
		border-radius: 0;
		outline: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;		

		&.slide-arrow{
			position: absolute;
			top: 50%;
			margin-top: -15px;
			z-index: 1;

			@media (max-width: 800px) {
				display: none !important;
			}

			&.prev-arrow,
			&.next-arrow{
				border: 1px solid var(--primary);
				background: var(--primary);
				border-radius: 50%;
				height: 65px;
				width: 65px;
				transition: ease all 300ms;

				svg{
					height: 20px;
					width: 20px;
				}

				&:hover{
					border: 1px solid var(--ternary);
					background: var(--ternary);
				}
			}

			&.prev-arrow{
				left: 80px;

				svg{
					transform: rotate(180deg);
				}
			}
			
			&.next-arrow{
				right: 80px;
			}
		}
	}

	.slick-dots {
		bottom: 10px;
		left: 330px;
		width: 40%;	
		text-align: left;
		margin-bottom: 10px;
		margin-left: 170px;
		
		@media (max-width: 991px) {
			width: 100%;
			text-align: center;
			margin: 0 0 10px;
			left: 0;
		}
			
		li {
			height: 10px;
			width: 10px;
			margin: 0 17px 0 0;

			button {
				border: 2px solid var(--font_secondary);
				border-radius: 50%;
				height: 10px;
				width: 10px;

				&:focus {
					background: var(--ternary);
				}

				&:before {
					color: transparent;
				}
				
				&[aria-selected]{
					border: 2px solid var(--ternary);
					background: var(--ternary);
				}
			}
		}
	}
}