.register-form{
	p{
		a{
			font-family: $font_inter;
			font-weight: 600;
			color: var(--font);
			text-decoration: none;

			&:hover{
				text-decoration: underline;
			}
		}
	}

	#customer-form{
		.custom-radio{
			input[type=radio]:checked+span{
				background-color: var(--primary);
			}
		}

		.form-group{
			label{
				color: var(--font);
			}

			.custom-checkbox{
				span{
					margin-top: 0;
				}
			}

			.form-control,
			.input-group{
				&.focus{
					outline: none;
				}

				&:focus{
					box-shadow: none;
					border-color: var(--primary);
				}
			}

			.input-group-btn{
				@media (max-width: 600px) {
					display: none;
				}

				button{
					background: var(--ternary);
					color: var(--font_secondary);

					&:hover{
						background: var(--secondary);
					}
				}
			}

			.form-control-comment{
				color: var(--information);
			}
		}

		.js-terms{
			a{
				font-family: $font_inter;
				font-weight: 600;
				color: var(--font);

				&:hover{
					text-decoration: underline;
					color: var(--ternary);
				}
			}
		}

		.form-control-submit{
			background: var(--ternary);
			color: var(--font_secondary);
			border-radius: 50px;
			border: 0;
			font-size: 1.0625rem;
			padding: 20px 30px;
			text-decoration: none;
			font-family: $font_inter;
			font-weight: 600;
			transition: all .3s ease;

			&:hover{
				background: var(--secondary);
			}
		}
	}
}