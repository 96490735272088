#consult_product{
	color: var(--font);
	background: var(--background);

	.chapeau{
		background: var(--hover);
		padding: 5.5rem;
		margin: 0 0 56px;
		border: 0;

		@media (max-width: 450px) {
			padding-left: 3rem;
			padding-right: 3rem;
		}
		
		h1{
			font-size: 3.438rem;
			letter-spacing: -1.1px;
			font-family: $font_marcellus;
			font-weight: 400;

			@media (max-width: 450px) {
				font-size: 2.438rem;
			}
		}
	}

	#js-product-list-top{
		color: var(--font);

		.sort-by{
			@media (max-width: 991px) {
				text-align: left;
				padding: 0;
			}
		}

		.dropdown{
			@media (max-width: 768px) {
				width: 100%;
			}

			.dropdown-menu{
				background: var(--background);
				color: var(--font);
	
				a{
					text-decoration: none;
	
					&:hover{
						text-decoration: underline;
						background: var(--ternary);
					}
				}
			}
		}

		.filter-button{
			display: none;
		}
	}

	#js-product-list{
		color: var(--font);
		
		.products{
			margin-top: 3rem;
			margin-bottom: 3rem;
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			gap: 2rem;

			@media (max-width: 991px) {
				grid-template-columns: repeat(3, 1fr);
			}
			
			@media (max-width: 768px) {
				grid-template-columns: repeat(2, 1fr);
			}
			
			@media (max-width: 500px) {
				gap: 1rem;
			}

			@media (max-width: 450px) {
				grid-template-columns: repeat(1, 1fr);
			}
	
			.product{
				width: auto;
				min-width: unset;
	
				.product-miniature{
					font-family: $font_inter;
	
					.thumbnail-container{
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-bottom: 0;
						height: unset;
						width: unset;
						box-shadow: none;
						text-align: center;
		
						.product-thumbnail{
							position: relative;
		
							img{
								object-fit: cover;
								position: relative;
							}
		
							a.cercle{
								position: absolute;
								top: 50%;
								left: 50%;
								background: var(--secondary);
								font-family: $font_inter;
								font-weight: 500;
								transform: translate(-50%, -50%);
								color: var(--font_secondary);
								text-decoration: none;
								font-size: 1rem;
								width: 250px;
								height: 250px;
								border-radius: 50%;
								visibility: hidden;
								opacity: 0;
								transition: visibility .5s, opacity .5s ease;
								display: flex;
								align-items: center;
								justify-content: center;
								align-content: center;
	
								@media (max-width: 1520px) {
									width: 200px;
									height: 200px;
								}
								
								@media (max-width: 1280px) {
									width: 180px;
									height: 180px;
								}
								
								@media (max-width: 1199px) {
									width: 200px;
									height: 200px;
								}
								
								@media (max-width: 991px) {
									width: 250px;
									height: 250px;
								}
	
								&:hover{
									text-decoration: underline;
								}
	
								svg{
									fill: var(--font_secondary);
									width: 13px;
									height: 7px;
									transform: rotate(-90deg) translateX(-9px);
									display: inline-block;
									margin: -8px 0 10px;
								}
							}
		
							&:hover{
								a.cercle{
									visibility: visible;
									  opacity: 1;
								}
							}
						}
		
						.product-description{
							position: relative;
							text-align: center;
							z-index: unset;
							background: transparent;
							width: unset;
							height: unset;
							bottom: unset;
							box-shadow: none;
	
							&::after{
								content: none;
							}
		
							.product-title{
								font-weight: 600;
								margin-top: 0;
	
								a{
									color: var(--font);
									font-size: 1rem;
									text-decoration: none;
								}
							}
		
							.price{
								font-weight: 500;
								color: var(--ternary);
								font-size: 1.125rem;
								letter-spacing: -0.36px;
							}
						}

						.product-flags{
							li{
								&.product-flag.discount{
									background: var(--primary);
									color: var(--font);
								}
							}
						}
					}
				}			
			}
		}

		.pagination{
			color: var(--font);

			@media (max-width: 767px) {
				justify-content: space-between;
			}
			
			@media (max-width: 400px) {
				flex-direction: column-reverse;
			}

			div{
				@media (max-width: 991px) {
					padding: 0;
				}

				.page-list{
					background: var(--background);
					box-shadow: none;
	
					li{
						color: var(--font);
						font-family: $font_ibm;
						font-weight: 400;
	
						a{
							color: var(--font);
							text-decoration: none;
	
							&:hover{
								text-decoration: underline;
							}
						}
	
						&.current{
							a{
								color: var(--ternary);
								font-size: 1rem;
							}
						}
					}
				}
			}
		}

		.up{
			a{
				background: var(--ternary);
				color: var(--font_secondary);
				border-radius: 50px;
				border: 0;
				font-size: 1.0625rem;
				padding: 20px 30px;
				text-decoration: none;
				font-family: $font_inter;
				font-weight: 600;
				transition: all .3s ease;
			}
		}
	}
}