@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Inter:wght@300;500;600&family=Marcellus&display=swap');

$font_ibm: 'IBM Plex Sans', sans-serif;
$font_inter: 'Inter', sans-serif;
$font_marcellus: 'Marcellus', serif;

//RWD -----------------------------------------------------------------------------------------------

$black: #000000;

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1300px;

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

$screen-sm-max: 575.98px;
$screen-md-max: 767.98px;
$screen-lg-max: 991.92px;
$screen-xl-max: 1199.98px;
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

@mixin sm-max {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin md-max {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin lg-max {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin xl-max {
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin sm-min {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md-min {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg-min {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl-min {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin sm-only {
  @media (min-width: #{$screen-sm-min}) and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin md-only {
  @media (min-width: #{$screen-md-min}) and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin lg-only {
  @media (min-width: #{$screen-lg-min}) and (max-width: #{$screen-xl-max}) {
    @content;
  }

}


/* Fix IE Only */
@mixin ie11 {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    @content;
  }
}
