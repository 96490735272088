body#order-detail{
	#wrapper{
		#main{
			#content{
				.box,
				#order-history,
				.addresses,
				#notifications{
					box-shadow: none;
					margin: 0;
				}

				a{
					color: var(--font);
					text-decoration: none;
					font-family: $font_inter;
					font-weight: 600;

					&:hover{
						text-decoration: underline;
					}
				}

				ul{
					padding-left: 0;
				}

				table{
					color: var(--font);
					border-color: var(--secondary);
	
					thead{
						th{
							color: var(--font);		
						}
					}
	
					tbody{
						tr{
							&:nth-of-type(odd){
								background: var(--hover);
							}
							
							&:nth-of-type(even){
								background: var(--primary);
							}

							&:hover{
								opacity: .8;
							}
	
							td{
								a{
									color: var(--font);
									text-decoration: none;
									font-family: $font_inter;
									font-weight: 600;
	
									&:hover{
										text-decoration: underline;
									}
								}
							}
						}
					}
	
					&.table-bordered{
						td, th{
							border: none !important;
						}
					}
				}

				form{
					background: var(--background);
					color: var(--font);
	
					.custom-radio{
						input[type=radio]:checked+span{
							background-color: var(--primary);
						}
					}
			
					.form-group{
						label{
							color: var(--font);
						}
			
						.custom-checkbox{
							span{
								margin-top: 0;
							}
						}
			
						.form-control,
						.input-group{
							&.focus{
								outline: none;
							}
			
							&:focus{
								box-shadow: none;
								border-color: var(--primary);
							}
						}
					}
			
					.form-control-submit{
						background: var(--ternary);
						color: var(--font_secondary);
						border-radius: 50px;
						border: 0;
						font-size: 1.0625rem;
						padding: 20px 30px;
						text-decoration: none;
						font-family: $font_inter;
						font-weight: 600;
						transition: all .3s ease;
			
						&:hover{
							background: var(--secondary);
						}
					}
				}
			}
		}
	}
}