// Global
body{
	font-family: $font_ibm;
	font-weight: 400;

	#wrapper{
		background: var(--background);
		box-shadow: none;
		padding-top: 0;
	}

	h1,
	h2,
	h3{
		text-transform: unset !important;
	}

	p{
		color: inherit;
	}

	.label,
	label{
		color: inherit;
		text-align: inherit;
		font-size: inherit;
		margin-bottom: 0;
	}

	.container,
	.row,
	.card-block,
	.modal-header{
		&::after{
			content: none;
		}
	}

	.collapse{
		display: inherit;
	}

	.page-footer{
		display: none;
	}

	.text-justify{
		text-align: justify;
	}

	.grecaptcha-badge{
		display: none;
	}
}