#produits_unes{
	background: var(--background);
	margin-top: 7.5rem;

	h2{
		font-size: 3rem;
    	letter-spacing: -0.0125rem;
		color: var(--ternary);
		font-family: $font_marcellus;
		font-weight: 400;
		position: relative;
		margin-bottom: 0;

		&::after {
			content: "";
			position: absolute;
			border-top: 1px solid var(--information);
			width: 5.625rem;
			margin: 0 1rem;
			transform: translateY(95px);
			left: 47%;
			opacity: .35;

			@media (max-width: 1200px) {
				display: none;
			}
		}
	
	}

	.products{
		padding-left: 11rem;
		padding-right: 11rem;
		margin-top: 6rem;
		margin-bottom: 7rem;

		@media (max-width: 1250px) {
			padding-left: 9rem;
			padding-right: 9rem;
		}
		
		@media (max-width: 991px) {
			padding-left: 6rem;
			padding-right: 6rem;
		}
		
		@media (max-width: 500px) {
			padding-left: 3rem;
			padding-right: 3rem;
		}
		
		@media (max-width: 400px) {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		.product{
			width: auto;
			min-width: unset;

			.product-miniature{
				font-family: $font_inter;

				.thumbnail-container{
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-bottom: 0;
					height: unset;
					width: unset;
					box-shadow: none;
	
					.product-thumbnail{
						position: relative;
						height: unset;
	
						img{
							object-fit: cover;
							position: relative;
							height: 100%;
							width: 100%;

							@media (max-width: 991px) {
								max-height: 300px;
								max-width: 300px;
							}
						}

						a.cercle{
							position: absolute;
							top: 50%;
							left: 50%;
							background: var(--secondary);
							font-family: $font_inter;
							font-weight: 500;
							transform: translate(-50%, -50%);
							color: var(--font_secondary);
							text-decoration: none;
							font-size: 1rem;
							width: 250px;
							height: 250px;
							border-radius: 50%;
							visibility: hidden;
							opacity: 0;
							transition: visibility .5s, opacity .5s ease;
							display: flex;
							align-items: center;
							justify-content: center;
							align-content: center;

							@media (max-width: 1520px) {
								width: 200px;
								height: 200px;
							}
							
							@media (max-width: 1280px) {
								width: 180px;
								height: 180px;
							}
							
							@media (max-width: 1199px) {
								width: 200px;
								height: 200px;
							}
							
							@media (max-width: 991px) {
								width: 250px;
								height: 250px;
							}

							&:hover{
								text-decoration: underline;
							}

							svg{
								fill: var(--font_secondary);
								width: 13px;
								height: 7px;
								transform: rotate(-90deg) translateX(-9px);
								display: inline-block;
								margin: -8px 0 10px;
							}
						}
	
						&:hover{
							a.cercle{
								visibility: visible;
								opacity: 1;
							}
						}
					}
	
					.product-description{
						position: relative;
						text-align: center;
						z-index: unset;
						background: transparent;
						width: unset;
						height: unset;
						bottom: unset;

						&::after{
							content: none;
						}
	
						.product-title{
							font-weight: 600;
							margin-top: 0;
							text-transform: uppercase !important;
							padding: 25px 60px 5px;
							line-height: 1rem;

							a{
								color: var(--font);
								font-size: 1rem;
								text-decoration: none;
							}
						}
	
						.price{
							font-weight: 500;
							color: var(--ternary);
							font-size: 1.125rem;
							letter-spacing: -0.36px;
						}
					}

					.product-flags{
						li{
							&.product-flag.discount{
								background: var(--primary);
								color: var(--font);
							}
						}
					}
				}
			}			
		}

		.slick-track {
			display: flex !important;
		}
	
		.slick-slide{
			height: inherit !important;
		}

		.slick-dots {
			display: none !important;

			@media (max-width: 800px) {
				display: block !important;
				bottom: -1.8rem;
	
				li {
					height: 10px;
					width: 10px;
		
					button {
						border: 2px solid var(--secondary);
						border-radius: 50%;
						height: 14px;
						width: 14px;
		
						&:focus {
							background: var(--primary);
						}
		
						&:before {
							color: transparent;
						}
		
						&[aria-selected]{
							border: 2px solid var(--primary);
							background: var(--primary);
						}
					}
				}
			}
		}
	
		button{
			margin: 0;
			padding: 0;
			background: none;
			border: none;
			border-radius: 0;
			outline: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;		

			&.slide-arrow{
				position: absolute;
				top: 50%;
				margin-top: -15px;

				@media (max-width: 800px) {
					display: none !important;
				}

				&.prev-arrow,
				&.next-arrow{
					border: 1px solid var(--information);
					border-radius: 50%;
					height: 65px;
					width: 65px;
					transition: ease all 300ms;

					svg{
						height: 20px;
						width: 20px;
					}

					&:hover{
						border: 1px solid var(--secondary);
						background: var(--secondary);

						svg{
							fill: var(--font_secondary);
						}
					}
				}

				&.prev-arrow{
					left: 70px;
					transform: translateY(-50%);
					z-index: 1;

					@media (max-width: 1250px) {
						left: 60px;
						transform: translateY(-100%);
					}
					
					@media (max-width: 991px) {
						left: 30px;
						transform: translateY(-50%);
					}

					svg{
						transform: rotate(180deg);
					}
				}
				
				&.next-arrow{
					right: 70px;
					transform: translateY(-50%);
					z-index: 1;

					@media (max-width: 1250px) {
						right: 60px;
						transform: translateY(-100%);
					}
					
					@media (max-width: 991px) {
						right: 30px;
						transform: translateY(-50%);
					}
				}
			}
		}
	}

	#all_produits_unes{
		a{
			background: var(--ternary);
			color: var(--font_secondary);
			border-radius: 50px;
			border: 0;
			font-size: 1rem;
			padding: 20px 45px;
			text-decoration: none;
			font-family: $font_inter;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: -0.32px;
			clear: none;
			margin-top: 0;
			margin-bottom: 0;
			float: none !important;

			svg {
				width: 20px;
				fill: var(--font_secondary);
			}

			&:hover{
				background: var(--secondary);
			}
		}
	}
}
