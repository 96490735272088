#actualites{
	background: var(--hover);
	color: var(--font);
	padding: 9rem 8rem 9.5rem 8rem;

	@media (max-width: 991px) {
		padding: 2rem;
	}

	h1{
		margin-bottom: 3.5rem;
		font-size: 3rem;
    	letter-spacing: -0.0125rem;
		font-family: $font_marcellus;
		font-weight: 400;
		color: var(--ternary);

		@media (max-width: 991px) {
			margin-bottom: 1.5rem;
			font-size: 2.5rem;
			letter-spacing: -0.05rem;
		}
	}


	.actualite{
		padding-left: 2.5rem;
		padding-right: 2.5rem;

		@media (max-width: 1400px) {
			padding-left: 2rem;
			padding-right: 2rem;
		}
		
		@media (max-width: 1275px) {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		@media (max-width: 991px) {
			padding-left: 0;
			padding-right: 0;
		}
		
		.card{
			border: none;
			
			.img-fluid img{
				width: 100%;
				height: 300px;
				object-fit: cover;
			}

			.card-body{
				padding: 2.8rem 2.8rem 3.75rem 2.8rem;
				background: var(--background);
				transition:ease all 300ms;

				@media (max-width: 991px) {
					padding: 1.8rem 1.5rem 2.75rem;
					min-height: 200px;
				}

				.card-title{
					font-size: .938rem;
					font-family: $font_inter;
					font-weight: 600;

					a{
						color: inherit;
						text-decoration: none;
						text-transform: uppercase;
						font-size: 1rem;
					}
				}

				.card-text{
					letter-spacing: -0.36px;
					font-size: 1.125rem;
					font-family: $font_inter;
					font-weight: 500;
					text-align: left;
					margin-bottom: 1.75rem;
					
					@media (max-width: 991px) {
						letter-spacing: inherit;
						font-family: $font_ibm;
						font-size: 1rem;
						margin-bottom: 2rem;
					}
				}

				.actu_more{
					bottom: 40px;
    				right: 30px;

					a{
						border-radius: 100%;
						padding: 22px;
						font-family: $font_inter;
						font-weight: 600;

						svg{
							height: 1.25rem;
						}
					}
				}
			}

			&:hover{
				.card-body{
					background: var(--secondary);

					.card-title{	
						a{
							color: var(--primary);
						}
					}

					.card-text{
						color: var(--font_secondary);
					}

					.actu_more{
						a{
							border: 1px solid var(--hover); //.25
							
							svg{
								fill: var(--font_secondary);
							}
						}
					}
				}
			}
		}

		&:nth-child(2){
			position: relative;

			.card{
				&::before {
					content: "";
					position: absolute;
					border-left: 1px solid var(--information);
					height: 2.5rem;
					margin: 0 1rem;
					transform: translateY(-40px);
					left: 47%;
					opacity: .35;
	
					@media (max-width: 1200px) {
						display: none;
					}
				}
			}		
		}
	}

	#all_actualite{
		padding-right: 1.75rem;

		@media (max-width: 1200px) {
			padding-right: 0;
		}

		a{
			color: inherit;
			font-family: $font_inter;
			font-weight: 600;
			text-decoration: underline;
		}

		&::before{
			content: url(../img/down.svg);
			width: 13px;
			height: 7px;
			transform: rotate(-90deg) translateX(-9px);
			display: inline-block;
			margin: -12px 10px 10px;
		}
	}

	.slick-track {
		display: flex !important;
	}

	.slick-slide{
		height: inherit !important;
	}

	.slick-dots {
		bottom: 0;

		li {
			height: 10px;
			width: 10px;

			button {
				border: 2px solid var(--secondary);
				border-radius: 50%;
				height: 10px;
				width: 10px;

				&:focus {
					background: var(--primary);
				}

				&:before {
					color: transparent;
				}

				&[aria-selected]{
					border: 2px solid var(--primary);
					background: var(--primary);
				}
			}
		}
	}
}