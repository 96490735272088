.login-form{
	form{
		label{
			color: var(--font);
		}

		.form-group{
			.form-control,
			.input-group{
				&.focus{
					outline: none;
				}

				&:focus{
					box-shadow: none;
					border-color: var(--primary);
				}
			}

			.input-group-btn{
				@media (max-width: 650px) {
					display: none;
				}

				button{
					background: var(--ternary) !important;
					color: var(--font_secondary) !important;
	
					&:hover{
						background: var(--secondary) !important;
					}
				}
			}
		}

		.forgot-password{
			a{
				text-decoration: none;
				color: var(--font);
				font-family: $font_inter;
				font-weight: 600;

				&:hover{
					text-decoration: underline;
					color: var(--font);
				}
			}
		}

		.form-footer{
			button{
				background: var(--ternary);
				color: var(--font_secondary);
				border-radius: 50px;
				border: 0;
				font-size: 1.0625rem;
				padding: 20px 30px;
				text-decoration: none;
				font-family: $font_inter;
				font-weight: 600;
				transition: all .3s ease;

				&:hover{
					background: var(--secondary);
				}
			}
		}
	}
}

.no-account{
	a{
		text-decoration: none;
		color: var(--font);
		font-family: $font_inter;
		font-weight: 600;

		&:hover{
			text-decoration: underline;
			color: var(--font);
		}
	}
}