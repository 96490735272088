#content{
	margin-bottom: 0 !important;
	padding: 0;
	box-shadow: unset;
	border: none;
	color: var(--font);
	background: var(--background);

	#content-wrapper{
		#reservation{
			.chapeau{
				background: var(--hover);
				padding: 5.5rem;
				margin: 0 0 56px;
				border: 0;

				@media (max-width: 450px) {
					padding-left: 3rem;
					padding-right: 3rem;
				}
				
				h1{
					font-size: 3.438rem;
					letter-spacing: -1.1px;
					font-family: $font_marcellus;
					font-weight: 400;

					@media (max-width: 450px) {
						font-size: 2.438rem;
					}
				}
			}

			.page-content{
				iframe{
					width: 100%;
					height: 100%;
					min-height: 750px;
				}
			}
		}
	}
}