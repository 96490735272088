#header{
	.header-nav{
		background: var(--ternary);
		color: var(--font_header);
		font-family: $font_ibm;
		border: none;
		max-height: unset;
		margin-bottom: 0;

		#info_header{
			padding: 0 8rem;

			@media (max-width: 1520px) {
				padding: 0 4rem;
			}
			
			@media (max-width: 1370px) {
				padding: 0 2rem;
			}
			
			@media (max-width: 970px) {
				padding: 0 1rem;
			}

			.tel{
				font-size: 1.375rem;
				letter-spacing: -0.88px;
				font-family: $font_inter;
				font-weight: 600;
			}
	
			.info_tel,
			.hours,
			#rs{
				letter-spacing: -0.32px;
			}

			@media (max-width: 1230px) {
				#rs{
					display: none !important;
				}
			}
	
			@media (max-width: 860px) {
				.info_tel{
					display: none;
				}
			}
	
			svg{
				fill: var(--primary);
				height: 1.25rem;
			}
			
			.reseaux_sociaux{
				padding: 0 .5rem 0 0;

				svg{
					fill: var(--font_header);
					height: 25px;
					width:25px;
					transition:ease all 300ms;

					&:hover{
						fill: var(--primary);
					}
				}
			}			
		}
	}

	.header-top{
		padding-bottom: 0;

		#site-navigation{
			color: var(--font);
			background: var(--background);
			font-family: $font_inter;
			font-weight: 600 !important;
			margin: 0;
			padding-left: 0;
			box-shadow: 0 7px 20px rgba($black, .125);
		
			#logo{
				max-width: 330px;
				min-width: 220px;
				max-height: 80px;
				margin-left: 10%;
				color: var(--logo);
			}
		
			.navbar-toggler{
				border: none;
				background-image: none;
		
				&:focus{
					box-shadow: none;
					outline: none;
				}
		
				.custom-navbar-toggler-icon{
					svg{
						height: 25px;
						fill: var(--secondary);
					}
				}
			}
			
			hr{
				background-color: var(--primary);
				height: 3px;
				opacity: 1;
			}
		
			#main_menu{
				min-height: unset;

				.menu{
					padding-left: 0;
					margin-bottom: 0;

					@media (max-width: 991px) {
						width: 100%;
					}

					#top-menu{
						display: flex;
						justify-content: flex-end;
						align-items: center;
						margin: auto;
			
						@media (max-width: 991px) {
							max-width: 75%;
							margin: auto;
							display: flex;
							flex-direction: column;
							align-items: center;
							margin-bottom: 2rem;
						}
			
						li{
							display: flex;
							flex-direction: column;
							padding-right: 1.5rem;
							letter-spacing: -0.34px;
							list-style: none;
							position: relative;

							@media (max-width: 1550px) {
								padding-right: .75rem;
							}
							
							@media (max-width: 1425px) {
								padding-right: .5rem;
							}
							
							@media (max-width: 1300px) {
								padding-right: .69rem;
							}
			
							@media (max-width: 991px) {
								padding-right: 0;
								width: 100%;
							}
							
							a{
								width: 100%;
								text-decoration: none;
								padding: .1875rem .625rem .375rem;
								text-transform: none;

								@media (max-width: 1550px) {
									padding: .1875rem .5rem .375rem;
								}

								@media (max-width: 1425px) {
									padding: .1875rem .25rem .375rem;
								}
								
								@media (max-width: 1300px) {
									padding: .1875rem 0rem .375rem;
								}

								@media (max-width: 991px) {
									transition: all .2s ease-in-out;
									padding: 10px 5%;
									border-bottom: 1px solid var(--information);
								}
							}
							
							&.menu-item-has-children{
								a{
									display: flex;
			
									&.active{
										&::after{
											transform: rotate(180deg);
											padding-bottom: 1.5rem;
										}
									}
			
									&::after{
										content: url(../img/down.svg);
										width: 13px;
										height: 13px;
										margin-top: -1px;
										margin-left: .3rem;
									}
			
									&:hover{
										text-decoration: none;
									}
			
									@media (max-width: 991px) {
										justify-content: space-between;
									}
								}
			
								&:hover ul{
									display: flex;
									transition: .4s;
			
									@media (max-width: 991px) {
										display: none;
										border-top: none;
									}
								}
			
			
								ul{
									display: none;
									flex-direction: column;
									background: var(--background);
									border-top: 3px solid var(--primary);
									min-width: 250px;
									padding: 20px 0;
									float: left;
									position: absolute;
									top: 100%;
									left: auto;
									z-index: 99999;
									margin-left: 0;
									opacity: 1;
									visibility: visible;
			
									@media (max-width: 991px) {
										position: relative;
										box-shadow: none;
										top: unset;
										left: unset;
										padding: 0 0 0 7%;
										width: 100%;
										border-top: none;
									}
			
									li{
										padding: 0 20px;
										margin: 0;
										letter-spacing: -0.34px;
										line-height: 2rem;
			
										@media (max-width: 991px) {
											padding: 0;
										}
										
										@media (max-width: 335px) {
											padding-right: 10px;
										}
			
										&:hover{
											background: var(--hover);
			
											@media (max-width: 991px) {
												background: transparent;
											}
										}
			
										a{
											padding: 10px 5%;
			
											&::after{
												content: unset !important;	
											}
										}
									}
								}
							}
	
							button{
								border: none;
								background: transparent;
								padding: 0;
			
								svg{
									height: 27px;
									width: 27px;
									fill: var(--font);
								}
							}
						}
					}
				}

				@media (max-width: 991px) {
					#_desktop_user_info{
						display: none;
					}
				}
			}
		
			#btn_header{
				background: var(--primary);
				letter-spacing: -0.32px;
				text-align: center;

				@media (max-width: 1550px) {
					padding-left: 1rem !important;
				}
				
				a{
					font-size: 1rem;
				
					&:hover{
						text-decoration: none;
					}
				}
		
				.height_button{
					padding-top: 3rem!important;
					padding-bottom: 3rem!important;

					@media (max-width: 1199px) {
						padding-top: 1.5rem!important;
						padding-bottom: 1.5rem!important;
					}
				}

				.link_btn_header{
					svg{
						height: 20px;
						fill: var(--font);
					}
				}
			}
		
			a{
				color: var(--font);
				letter-spacing: 0.0025rem;
				font-size: 1.0625rem;
		
				&:hover{
					text-decoration: underline;
					text-decoration-thickness: .15rem;
				}
			}
		}

		#_desktop_user_info{
			.user-info{
				a{
					text-decoration: none;
					padding: 0;
	
					svg{
						height: 17px;
    					width: 17px;
						fill: var(--font);

						@media (max-width: 1199px) {
							height: 20px;
							width: 20px;
						}
					}
	
					&:hover{
						text-decoration: underline;
					}
				}

				#user_login{
					display: none;
					flex-direction: column;
					background: var(--background);
					border-top: 3px solid var(--primary);
					min-width: 220px;
					padding: 20px 0;
					float: left;
					position: absolute;
					top: 100%;
					right: 0;
					left: auto;
					z-index: 99999;
					margin-left: 0;
					margin-bottom: 0;
					opacity: 1;
					visibility: visible;
					box-shadow: 2px 1px 11px 2px rgba(0, 0, 0, .1);

					li{
						padding: 0 20px;
						margin: 0;
						letter-spacing: -0.34px;
						line-height: 2rem;

						&:hover{
							background: var(--hover);
						}

						a{
							padding: 10px 5%;
							margin-right: 0 !important;
							display: flex;
							justify-content: flex-end;

							&::after{
								content: unset !important;	
							}
						}
					}
				}

				&.is_login{
					&:hover #user_login{
						display: flex;
					}
				}
			}
		}

		#_desktop_cart{
			.blockcart{
				background: transparent;
	
				a{
					text-decoration: none;
					text-align: center;
					padding: 0;

					.cart-products-count{
						font-size: 1rem;
						color: var(--font);
					}
				}

				svg{
					height: 22px;
    				width: 22px;
					fill: var(--font);

					@media (max-width: 1199px) {
						height: 20px;
						width: 20px;
					}
				}
			}
		}
	}
}