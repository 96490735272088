.contact-form{
	background: var(--background);

	h1,
	label,
	input,
	select{
		color: var(--font);
	}

	h1{
		font-size: 3.438rem;
		letter-spacing: -1.1px;
		font-family: $font_marcellus;
		font-weight: 400;

		@media (max-width: 450px) {
			font-size: 2.438rem;
		}
	}

	form{
		.custom-radio{
			input[type=radio]:checked+span{
				background-color: var(--primary);
			}
		}

		.form-group{
			label{
				color: var(--font);
			}

			.custom-checkbox{
				span{
					margin-top: 0;
				}
			}

			.form-control,
			.input-group{
				&.focus{
					outline: none;
				}

				&:focus{
					box-shadow: none;
					border-color: var(--primary);
				}
			}

			.input-group-btn{
				@media (max-width: 720px) {
					display: none;
				}

				button,
				label{
					background: var(--ternary);
					color: var(--font_secondary);
					height: 38px;

					&:hover{
						background: var(--secondary);
					}
				}
			}

			.form-control-comment{
				color: var(--information);
			}
		}


		input[type="submit"]{
			background: var(--ternary);
			color: var(--font_secondary);
			border-radius: 50px;
			border: 0;
			font-size: 1.0625rem;
			padding: 20px 30px;
			text-decoration: none;
			font-family: $font_inter;
			font-weight: 600;
			transition: all .3s ease;

			&:hover{
				background: var(--secondary);
			}
		}
	}
}