#qui_sommes_nous{
	color: var(--font);
	height: 700px;
	margin-top: 11rem;
	margin-bottom: 11rem;

	@media (max-width: 668px) {
		height: 500px;
	}

	#background_gauche{
		background: var(--secondary);
		
		img{
			object-fit: cover;
		}
	}

	#infos{
		padding: 7.938rem 12rem 0;

		@media (max-width: 1400px) {
			padding: 6rem 8rem 0;
		}
		
		@media (max-width: 890px) {
			padding: 2rem 4rem 0;
		}
		
		@media (max-width: 668px) {
			padding: 1rem 1rem 0;
		}

		h1{
			font-family: $font_marcellus;
			font-weight: 400;
			font-size: 2.063rem;
			color: var(--ternary);
		}

		#slogan{
			font-size: 3rem;
			font-family: $font_inter;
			font-weight: 300;
			letter-spacing: -2px;

			@media (max-width: 668px) {
				font-size: 2rem;
			}
		}

		#description{
			letter-spacing: -0.32px;
			font-family: $font_ibm;
			font-weight: 400;
		}

		#qsn_link{
			@media (max-width: 550px) {
				text-align: center;
			}

			#link_more{
				background: var(--ternary);
				color: var(--font_secondary);
				border-radius: 50px;
				border: 0;
				font-weight: 600;
				font-size: 1.0625rem;
				padding: 20px 50px;
				text-decoration: none;
				font-family: $font_inter;
				font-weight: 600;
				transition: ease all 300ms;
	
				&:hover{
					background: var(--secondary);
				}
			}
		}
		
	}
}