body{
	&.page-customer-account,
	&.page-addresses{
		#content{
			box-shadow: none;

			form{
				background: var(--background);
				color: var(--font);

				.custom-radio{
					input[type=radio]:checked+span{
						background-color: var(--primary);
					}
				}
		
				.form-group{
					label{
						color: var(--font);
					}
		
					.custom-checkbox{
						span{
							margin-top: 0;
						}
					}
		
					.form-control,
					.input-group{
						&.focus{
							outline: none;
						}
		
						&:focus{
							box-shadow: none;
							border-color: var(--primary);
						}
					}
		
					.input-group-btn{
						@media (max-width: 768px) {
							display: none;
						}
		
						button{
							background: var(--ternary);
							color: var(--font_secondary);
		
							&:hover{
								background: var(--secondary);
							}
						}
					}
		
					.form-control-comment{
						color: var(--information);
					}
				}
		
				.js-terms{
					a{
						font-family: $font_inter;
						font-weight: 600;
						color: var(--font);
		
						&:hover{
							text-decoration: underline;
							color: var(--ternary);
						}
					}
				}
		
				.form-control-submit{
					background: var(--ternary);
					color: var(--font_secondary);
					border-radius: 50px;
					border: 0;
					font-size: 1.0625rem;
					padding: 20px 30px;
					text-decoration: none;
					font-family: $font_inter;
					font-weight: 600;
					transition: all .3s ease;
		
					&:hover{
						background: var(--secondary);
					}
				}
			}

			.address{
				box-shadow: none;
				background: var(--background);
				color: var(--font);

				&:hover{
					background: var(--hover);
				}

				.address-body{
					h4{
						font-family: $font_inter;
						font-weight: 600;
						font-size: 1.75rem;
						color: var(--ternary)
					}

					address{
						font-family: $font_inter;
						font-weight: 500;
						font-size: 1rem;
						min-height: unset;
					}
				}

				.address-footer{
					border-color: var(--font);

					a{
						color: var(--information);
						text-decoration: none;

						&:hover{
							text-decoration: underline;
						}
					}
				}	
			}

			.addresses-footer{
				margin-bottom: 2rem;

				a{
					color: var(--font);
					text-decoration: none;
					font-family: $font_inter;
					font-weight: 600;

					&:hover{
						text-decoration: underline;
					}
				}
			}

			h6{
				color: var(--font);
				font-family: $font_inter;
				font-weight: 600;
			}

			table{
				color: var(--font);
				border-color: var(--secondary);

				thead{
					th{
						color: var(--font);		
					}
				}

				tbody{
					tr{
						&:nth-of-type(odd){
							background: var(--hover);
						}
						
						&:nth-of-type(even){
							background: var(--primary);
						}

						&:hover{
							opacity: .8;
						}

						td{
							a{
								color: var(--font);
								text-decoration: none;
								font-family: $font_inter;
								font-weight: 600;

								&:hover{
									text-decoration: underline;
								}
							}
						}
					}
				}

				&.table-bordered{
					td, th{
						border: none !important;
					}
				}
			}

			.psgdprinfo17{
				float: none;
				background: var(--background);
				color: var(--font);

				&:nth-child(2){
					margin-top: 3rem;

					@media (max-width: 768px) {
						margin-top: 0;
					}
				}

				h2{
					color: var(--ternary);
					font-family: $font_inter;
					font-weight: 600;
				}

				a{
					color: var(--font);
					font-family: $font_inter;
					font-weight: 600;
					text-decoration: underline;

					&.btn{
						background: var(--ternary);
						color: var(--font_secondary);
						border-radius: 50px;
						border: 0;
						font-size: 1.0625rem;
						padding: 20px 30px;
						text-decoration: none;
						font-family: $font_inter;
						font-weight: 600;
						transition: all .3s ease;

						@media (max-width: 768px) {
							float: none;
							margin-bottom: 1rem;
						}
			
						&:hover{
							background: var(--secondary);
						}
					}
				}
			}
		}
	}
}