#colophon{
	background: var(--secondary);
	color: var(--font_secondary);

	#pop-up{
		color: var(--font);
		opacity: 1;
		
		.modal-content{
			background: var(--hover);
			border: none;

			.modal-header{
				border: none;

				.modal-title{
					font-family: $font_marcellus;
					font-weight: 400;
				}

				.btn-close{
					margin: -.5rem -.5rem -.5rem 0.5rem;
				}
			}

			.modal-body{
				font-family: $font_ibm;
				font-weight: 400;

				.img-fluid{
					max-width: 468px;
					max-height: 468px;

					@media (max-width: 500px) {
						max-width: unset;
						max-height: unset;
					}

					img{
						object-fit: cover;
						max-width: 468px !important;
						max-height: 468px !important;

						@media (max-width: 500px) {
							max-width: unset;
							max-height: unset;
							width: 100%;
							height: 100%;
						}
					}
				}
			}

			.modal-footer{
				border: none;
				justify-content: center;
	
				#link_modal{
					background: var(--primary);
					letter-spacing: -.32px;
					text-align: center;
					padding: 1rem 2rem;
					border-radius: 50px;

					a{
						color: var(--font);
						font-size: 1rem;
						letter-spacing: .0025rem;
						font-family: $font_inter;
						font-weight: 600;
						text-decoration: none;

						&:hover{
							text-decoration: underline;
						}

						svg{
							height: 20px;
    						fill: var(--font);
						}
					}
				}
			}
		}
	}

	#modal_search{
		color: var(--font);
		opacity: 1;

		.modal-content{
			background: var(--background);
			border: none;

			.modal-header{
				border: none;

				.modal-title{
					font-family: $font_marcellus;
					font-weight: 400;
				}

				.btn-close{
					margin: -.5rem -.5rem -.5rem 0.5rem;
				}
			}

			.modal-body{
				font-family: $font_ibm;
				font-weight: 400;

				#search_widget{
					width: 100%;

					form{
						input{
							height: 100%;
							width: 100%;
							color: var(--font);
							border: solid 1px var(--primary);

							&::placeholder{
								color: var(--font);
							}

							&:focus{
								outline: none;
								background: inherit;
							}
						}
	
						button{
							display: flex;
							align-items: center;
							background: var(--primary);
							color: var(--font);
							letter-spacing: -.32px;
							text-align: center;
							border: none;
							height: 100%;
							bottom: 0;
							right: 0;
		
							span{
								display: inline-block !important;
							}

							.search{
								&:hover{
									color: var(--font);
								}
							}
						}

						input:focus+button .search{
							color: var(--font);
						}
					}
				}
			}
		}
	}

	#footer-top{
		#newsletter{
			padding-top: 7rem;
			padding-bottom: 3.5rem;
			
			@media (max-width: 991px) {
				padding-top: 3rem;
			}

			[id*="blockEmailSubscription_"]{
				background: var(--secondary);
				color: var(--font_header);
				border: none !important;

				#footer-newsletter{
					@media (max-width: 768px) {
						text-align: center !important;
					}

					.alert{
						position: absolute;
						top: -100%;
						right: 0;
						width: 100%;

						@media (max-width: 968px) {
							top: -52%;
						}
						
						@media (max-width: 340px) {
							padding-left: 0;
							padding-right: 0;
						}
					}
	
					label{
						font-size: 1rem;
						letter-spacing: -0.34px;
						font-family: $font_inter;
						font-weight: 600;
					}
		
					#mail_news{
						background: var(--font_header);
						margin-left: 2rem;
						border-radius: 30px;
						border: 0;
						font-weight: 600;
						font-size: 1.0625rem;
						padding: 10px 10px 10px 20px;
						display: inline;
						position: relative;

						@media (max-width: 991px) {
							margin-left: 0;
						}
		
						input[type="email"]{
							border: none;
							color: var(--font);
							width: 90%;
							
							&::placeholder{
								color: var(--font);
								font-family: $font_ibm;
							}

							&:focus{
								padding: 11px;
							}
						}
					}

					button{
						display: block;
						background: transparent;
						border: none;
						bottom: 1rem;
    					right: 1rem;
		
						svg{
							height: 20px;
							fill: var(--font);
						}
					}

					#gdpr_consent{
						margin-left: -15px;
						opacity: .4;

						label {
							font-family: $font_ibm;
							font-weight: 400;
						}

						input[type=checkbox]+span{
							border-color: var(--font_secondary);
						}

						.material-icons{
							color: var(--font_secondary);
						}
					}
				}	
			}
	
			#rsf{
				@media (max-width: 1199px) {
					align-self: center !important;
				}

				@media (max-width: 450px) {
					justify-content: center !important;
				}

				.reseaux_sociaux{
					svg{
						fill: var(--font_header);
						height: 30px;
						width: 30px;
						margin-left: 15px;

						@media (max-width: 991px) {
							margin-left: 0;
							margin-right: 15px;
						}
					}

					@media (max-width: 450px) {
						margin-bottom: 1rem;
					}
				}
			}
		}

		hr{
			background-color: var(--information);
			opacity: .35;
		}
	}

	#footer-middle{
		padding-top: 4rem;
		padding-bottom: 4rem;
		font-family: $font_ibm;
		font-weight: 400;

		#logof{
			color: var(--font_secondary);
			text-decoration: none;
			font-size: 2rem;

			&:hover{
				text-decoration: underline;
			}
		}

		#info_footer-middle{
			div{
				margin-bottom: .25rem;
			}

			a[href^="mailto:"]{
				color: var(--primary);
			}
		}

		[class*="menu-pied-de-page"] ul{
			list-style: none;
			margin: 0;
			padding: 0;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 1rem;
			font-family: $font_inter;
			font-weight: 600;
			
			li{
				text-align: left;

				a{
					color: var(--font_header);
					text-decoration: none;
					letter-spacing: -0.34px;
					transition:ease all 300ms;
	
					&:hover{
						color: var(--primary);
						text-decoration: underline;
					}
				}

			}
		}
	}

	hr{
		background-color: var(--information);
		opacity: .35;
	}

	#footer-bottom{
		font-family: $font_ibm;
		font-weight: 400;

		.container{
			.is-information{
				align-self: center;

				@media (max-width: 767px) {
					align-self: unset;
					text-align: center;
				}
			}
		}

		a{
			color: var(--information);
			text-decoration: none;

			&:hover{
				text-decoration: underline;
			}

			@media (max-width: 767px) {
				display: block;
			}
		}

		[class*="menu-mentions-legales"] ul{
			display: flex;
			list-style: none;
			margin: 0;

			@media (max-width: 1201px) {
				justify-content: space-around;
				margin-bottom: 2rem;
			}

			@media (max-width: 767px) {
				padding-left: 0;
				text-align: center;
			}

			@media (max-width: 650px) {
				flex-wrap: wrap;
			}

			li{
				margin-left: 2rem;

				@media (max-width: 1201px) {
					margin-left: 0;
				}

				@media (max-width: 650px) {
					flex: 50%;
				}

				&:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)) {
					display: none;
					
				}
			}
		}
	}
}

// Id correspondant au block ul affichant les suggestions lors d'une recherche
#ui-id-1{
	z-index: 9999;
	
	li{
		font-size: 1rem;

		a{
			color: var(--font);

			&:hover{
				color: var(--ternary);
			}
		}
	}
}