#checkout{
	background: var(--background);
	color: var(--font);

	.cart-grid{
		.chapeau{
			background: var(--hover);
			padding: 5.5rem;
			margin: 0 0 56px;
			border: 0;
	
			@media (max-width: 450px) {
				padding-left: 3rem;
				padding-right: 3rem;
			}
			
			h1{
				font-size: 3.438rem;
				letter-spacing: -1.1px;
				font-family: $font_marcellus;
				font-weight: 400;
	
				@media (max-width: 450px) {
					font-size: 2.438rem;
				}
			}
		}

		.cart-grid-body{
			.card{
				box-shadow: none;
				border: none;

				.cart-items{
					.cart-item{
						@media (max-width: 768px) {
							border-bottom: solid 1px var(--information);
						}

						.product-line-grid{
							.product-line-grid-body{
								.product-line-info{
									a{
										&:hover{
											color: var(--ternary);
										}
									}
								}

								.regular-price{
									color: var(--information);
								}

								.discount{
									background: var(--primary);
									color: var(--font);
								}
								
								.price{
									color: var(--ternary);
								}
							}

							.product-line-actions{
								.row{
									@media (max-width: 768px) {
										.info_price{
											width: 75%;
										}

										.info_delete{
											width: 25%;
										}
									}

									.cart-line-product-actions{
										.remove-from-cart{
											.material-icons{
												&:hover{
													color: var(--ternary);
												}
											}	
										}
									}
								}
							}
						}
					}
				}
			}

			#go_back_purchase{
				&:hover{
					color: var(--ternary);
				}
			}
		}

		.cart-grid-right{
			border-left: solid 1px var(--primary);

			.card{
				box-shadow: none;
				border: none;

				.cart-detailed-totals{
					.cart-summary-totals{
						.cart-summary-line{
							background: var(--primary);
						}
					}

					.block-promo{
						a{
							color: var(--font);
							font-family: $font_inter;
							font-weight: 600;
							text-decoration: underline;

							&:hover{
								text-decoration: underline;
							}
						}
						

						form{
							background: var(--background);
							color: var(--font);

							input{
								&.focus{
									outline: none;
								}
				
								&:focus{
									box-shadow: none;
									border-color: var(--primary);
								}
							}
					
							button{
								background: var(--ternary);
								color: var(--font_secondary);
								height: 2.5rem;
								border: 0;
								font-size: 1.0625rem;
								text-decoration: none;
								font-family: $font_inter;
								font-weight: 600;
								transition: all .3s ease;
					
								&:hover{
									background: var(--secondary);
								}
							}
						}
					}
				}

				.checkout{
					text-align: center;
					
					a{
						background: var(--ternary);
						color: var(--font_secondary);
						border-radius: 50px;
						border: 0;
						font-size: 1.0625rem;
						padding: 20px 50px;
						text-decoration: none;
						font-family: $font_inter;
						font-weight: 600;
						transition: all .3s ease;

						&:hover{
							background: var(--secondary);
						}
					}
				}
			}

			.blockreassurance_product{
				div:not([class="block-title"]){
					display: flex;
					align-items: center;
					margin-bottom: 1rem;

					svg{
						height: 65px;
						width: 65px;
						fill: var(--ternary);
					}

					.block-title{
						font-family: $font_inter;
						font-weight: 600;
						font-size: 1rem;
					}
				}
			}
		}
	}
}