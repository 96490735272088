body#my-account{
	#main{
		#content{
			.row{
				margin: 0;

				.links{
					background: var(--background);
				
					a{
						color: var(--font);
						text-decoration: none;
						font-family: $font_inter;
						font-weight: 600;
				
						&:hover{
							background: var(--hover);
						}
				
						.link-item{
							box-shadow: none;
							background: unset;
				
							i{
								color: var(--primary);
							}
						}
					}
				}
			}
		}
	}
}