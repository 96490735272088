#block-reassurance{
	background: var(--secondary);
	color: var(--font_secondary);
	font-family: $font_inter;
	font-weight: 600;
	margin-top: 0;

	ul{
		display: flex;
		justify-content: space-between;
		list-style: none;
		padding-left: 0;
		margin-top: 1.5rem;
    	margin-bottom: 1.5rem;

		@media (max-width: 550px) {
			justify-content: center;
			flex-wrap: wrap;
		}

		li{
			border-bottom: none;
			margin-right: 1rem;
			margin-bottom: 1rem;

			.block-reassurance-item{
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 0;
	
				svg{
					height: 2.188rem;
					width: 2.188rem;
					margin-right: 0;
					margin-bottom: 1rem;
					fill: var(--font_secondary);
				}

				span{
					margin-bottom: 0;
					text-transform: uppercase;
				}
			}
		}
	}
}